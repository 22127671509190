<div class="container p-2">

  <div class="jumbotron mb-3">
    <h1 class="jumbotron-headline" i18n="Support headline">Support</h1>
    <p class="jumbotron-lead" i18n="Support lead text">Wenn Sie Hilfe benötigen, kontaktieren Sie mich bitte.</p>
  </div>

  <!-- Contact  -->
  <mat-card>
    <h1 class="paragraph-headline" i18n>Email</h1>
    <p i18n>Senden Sie eine Email an <a href="mailto:support@aSchwartz.de">support@aSchwartz.de</a> und ich antworte Ihnen schnellstmöglich.</p>
  </mat-card>

</div>

