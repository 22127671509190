<div class="container p-2">

  <!-- On small screens, show vertical layout (text and button below image) -->
  <div class="card w-100 mb-3 d-block d-md-none">
    <div class="row no-gutters">
      <div class="col">
        <div class="jumbotron-cropped-image-w100">
          <img [src]="titleImage" alt="Blitzshare" class="top-rounded-image" i18n-alt>
        </div>
      </div>
      <div class="col p-3">
        <ng-container *ngTemplateOutlet="jumbotronContent"></ng-container>
      </div>
    </div>
  </div>

  <!-- On larger screens, show horizontal layout (text and button next to image) -->
  <div class="card w-100 mb-3 d-md-block d-none">
    <div class="row no-gutters">
      <img [src]="imgPath + '/start/blitzshare-canoe-w500.webp'" alt="Blitzshare" class="jumbotron-cropped-image-w300px top-rounded-image" i18n-alt>
      <div class="col">
        <div class="card-body">
          <ng-container *ngTemplateOutlet="jumbotronContent"></ng-container>
        </div>
      </div>
    </div>
  </div>
  <ng-template #jumbotronContent>
    <h1 class="jumbotron-headline" i18n>Blitzshare</h1>
    <p class="jumbotron-lead">{{LEAD_BLITZSHARE}}</p>
    <a href="https://blitzshare.de" target="_blank" rel="noreferrer">
      <button color="primary" mat-flat-button>Zu Blitzshare.de</button>
    </a>
  </ng-template>


  <h1 class="paragraph-headline" i18n>Was ist Blitzshare?</h1>

  <p i18n>Blitzshare ist eine Plattform, die es ihren Nutzern ermöglicht Gegenstände zum Vermieten anzubieten sowie Gegenstände von anderen Nutzern zu
    mieten. Dabei gibt es keine Beschränkung auf eine bestimmte Kategorie oder Art von Gegenständen. Fast alles ist möglich von Sportgeräten über Werkzeuge bis
    hin zu Hochzeitskleidern.</p>
  <p i18n>Mit Start im August 2021 trifft Blitzshare voll den Zeitgeist: <span class="highlight-text">Sharing ist Caring</span>, <span
    class="highlight-text">Ressourcen schonen</span>
    und <span class="highlight-text">Emissionen verhindern</span>. Denn viele Leute können sich jetzt beispielsweise einen Bohrhammer teilen, statt für jeden
    einen eigenen produzieren zu müssen.
  </p>
  <p i18n></p>

  <div class="row">
    <div class="col-md-6">
      <h1 class="paragraph-headline" i18n>Gründe auf Blitzshare zu mieten</h1>
      <ul>
        <li><h3 class="paragraph-headline-3" i18n>Vor dem Kauf ausprobieren</h3>
          <p i18n>Möchtest du eine neue Sportart ausprobieren, aber bist nicht sicher, ob sie dir gefallen wird? Miete die Ausrüstung auf Blitzshare und finde
            es heraus!</p></li>

        <li><h3 class="paragraph-headline-3" i18n>Geld und Platz sparen</h3>
          <p i18n>Hast du schon mal ein Werkzeug gekauft, das du nur einmal gebraucht hast und seitdem liegt es nur herum? Wenn es doch nur eine bessere Lösung
            gäbe...</p></li>

        <li><h3 class="paragraph-headline-3" i18n>Hilf der Umwelt</h3>
          <p i18n>Teile Dinge mit anderen. Spare wertvolle Ressourcen. Reduziere Abfall. Reduziere Emissionen.</p></li>
      </ul>
    </div>
    <div class="col-md-6"><h1 class="paragraph-headline" i18n>Gründe auf Blitzshare zu vermieten</h1>
      <ul>
        <li><h3 class="paragraph-headline-3" i18n>Geld verdienen</h3>
          <p i18n>Besitzt du ein Fahrrad, ein Elektrowerkzeug, ein Kayak, Skiausrüstung oder irgendetwas anderes, was du nicht täglich benutzt? Vermiete es und
            verdiene Geld.</p></li>
        <li><h3 class="paragraph-headline-3" i18n>Hilf anderen, ihren Leidenschaften zu folgen</h3>
          <p i18n>Ermögliche es Menschen Dinge zu tun, die sie sonst nicht tun könnten. Wenn du z.B. in der Nähe eines Sees lebst und ein SUP hast, kannst du es
            an jemanden vermieten, der es sich kein eigenes leisten kann.</p></li>

        <li><h3 class="paragraph-headline-3" i18n>Neue Leute kennenlernen</h3>
          <p i18n>Wenn jemand deine Waren mietet, habt ihr wahrscheinlich ein gemeinsames Interesse. Vielleicht bleibt ihr ja in Kontakt.</p></li>
      </ul>
    </div>
  </div>


  <h1 class="paragraph-headline" i18n>Kategorien auf Blitzshare</h1>

  <p i18n>Blitzshare bietet eine Vielzahl an Kategorien, in denen die Nutzer Gegenstände mieten und vermieten können. <a
    href="https://blitzshare.de/de/browse/category" target="_blank" rel="noreferrer">Hier</a> finden Sie die gesamte Liste. Die folgenden Bilder vermitteln einen Eindruck von
    der Vielfalt:</p>
  <!-- Image -->
  <div *ngIf="imgUrlsObject" class="mb-3">
    <ngb-carousel #imageCarouselObject *ngIf="imgUrlsObject.length>0" [interval]="0" id="carouselObject">
      <ng-template (slid)="activeImageIndexObject = iObject" *ngFor="let imgUrl of imgUrlsObject; index as iObject" id="{{iObject}}" ngbSlide>
        <div class="wrapper">
          <picture>
            <source [srcset]="imgUrl.w350" media="(max-width: 350px)">
            <source [srcset]="imgUrl.w500" media="(max-width: 500px)">
            <source [srcset]="imgUrl.w750" media="(max-width: 750px)">
            <img (click)="openImage(iObject, imgUrlsObject)"
                 *ngIf="isImageActive(iObject,  'carouselObject')" [alt]="imgUrl.title" [src]="imgUrl.w1124">
          </picture>


        </div>
      </ng-template>
    </ngb-carousel>
    <br>
    <div class="thumbnail-container m-0 p-0">
      <app-thumbnail-widget (imgClicked)="selectImage($event.index, 'carouselObject')" [imgUrls]="imgUrlsObject"></app-thumbnail-widget>
    </div>
  </div>


</div>
