<footer class="py-4 bg-dark text-white-50">
  <div class="container">
    <div class="row">
      <div class="col links">
        <small i18n i18n-matTooltip matTooltip="Version {{version}}">Copyright &copy; {{year}} Alexander Schwartz</small> -
        <a [routerLink]="['/privacy-policy']"><small i18n>Datenschutzerklärung</small></a> -
        <a [routerLink]="['/imprint']"><small i18n>Impressum</small></a> -
        <a [routerLink]="['/contact']"><small i18n>Kontakt</small></a>
      </div>
      <div class="col social-media-bar">
        <app-social-media-bar></app-social-media-bar>
      </div>
    </div>
  </div>

</footer>
