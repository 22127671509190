import {AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {PlaceholderDirective} from '../../shared/directives/placeholder.directive';
import {AuthService} from '../auth.service';
import {AuthProvider} from 'ngx-auth-firebaseui';
import {Router} from '@angular/router';
import {BaseComponent} from '../../shared/components/base/base.component';
import {UserService} from '../../shared/services/user.service';
import {LinkMenuItem} from 'ngx-auth-firebaseui/lib/components/ngx-auth-firebaseui-avatar/ngx-auth-firebaseui-avatar.component';
import {MatDialog} from '@angular/material/dialog';
import {LoginDialogComponent, LoginDialogModel} from './login-dialog/login-dialog.component';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-login-button',
  templateUrl: './login-button.component.html',
  styleUrls: ['./login-button.component.css'],
})
export class LoginButtonComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  isLoggedIn = false;
  providers = AuthProvider;
  closeEventEmitter: EventEmitter<void> = new EventEmitter<void>();

  @ViewChild('loginButton') loginButtonRef ?: ElementRef;
  @ViewChild(PlaceholderDirective) alertHost: PlaceholderDirective | undefined;

  avatarLinks: LinkMenuItem[] = [];
  nonAdminAvatarLinks: LinkMenuItem[] = [];
  adminAvatarLinks: LinkMenuItem[] = [];

  constructor(authService: AuthService,
              userService: UserService,
              private router: Router,
              public dialog: MatDialog) {
    super(authService, userService);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.firestoreUser$.pipe(takeUntil(this.destroy$)).subscribe(user => {
      if (user?.admin)
        this.avatarLinks = this.adminAvatarLinks;
      else
        this.avatarLinks = this.nonAdminAvatarLinks;
      this.isLoggedIn = !!user;
    });

    this.authService.openLoginDropdownSubject.pipe(takeUntil(this.destroy$)).subscribe(value => this.showDialog());

    this.closeEventEmitter.pipe(takeUntil(this.destroy$)).subscribe(() => {
      setTimeout(args => this.dialog.closeAll(), 3000);
    });

  }

  logout(): void {
  }

  ngAfterViewInit(): void {
    // Quickly open and close the dialog. This initialized the login component. Without doing this, the user will not be logged in,
    // until they hover the login button or open the login dialog some other way.
    this.showDialog();
    this.dialog.closeAll();
  }

  showDialog() {
    if (!this.loginButtonRef)
      return;
    const dialogData = new LoginDialogModel(this.loginButtonRef, this.closeEventEmitter);
    const dialogRef = this.dialog.open(LoginDialogComponent, {
      maxWidth: '350px',
      data: dialogData,
      backdropClass: 'backdropBackground',
      panelClass: 'login-dialog-container',
    });
  }

}
