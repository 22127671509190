import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuthModule} from '../auth/auth.module';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from '../shared/shared.module';
import {MatIconModule} from '@angular/material/icon';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {ImprintComponent} from './imprint/imprint.component';
import {PrivacyPolicyComponent} from './privacy-policy/privacy-policy.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import {StartComponent} from './start/start.component';
import {NgbCarouselModule} from '@ng-bootstrap/ng-bootstrap';
import {ResponsibleContactDetailsComponent} from './imprint/responsible-contact-details/responsible-contact-details.component';
import {SupportComponent} from './support/support.component';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {NotFoundComponent} from './not-found/not-found.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {PagesRoutingModule} from './pages-routing.module';
import {SoftwareDevelopmentComponent} from './software-development/software-development.component';
import {PhotographyComponent} from './photography/photography.component';
import {RealEstateComponent} from './real-estate/real-estate.component';
import {BlitzshareComponent} from './blitzshare/blitzshare.component';
import {ContactComponent} from './contact/contact.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {NgxEditorModule} from 'ngx-editor';


@NgModule({
  declarations: [
    ImprintComponent,
    PrivacyPolicyComponent,
    StartComponent,
    ResponsibleContactDetailsComponent,
    SupportComponent,
    NotFoundComponent,
    SoftwareDevelopmentComponent,
    PhotographyComponent,
    RealEstateComponent,
    BlitzshareComponent,
    ContactComponent,
  ],
  imports: [
    CommonModule,
    AuthModule,
    RouterModule,
    ReactiveFormsModule,
    SharedModule,
    MatIconModule,
    MatBadgeModule,
    MatButtonModule,
    MatToolbarModule,
    MatMenuModule,
    NgbCarouselModule,
    MatCardModule,
    MatListModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    PagesRoutingModule,
    MatCheckboxModule,
    NgxEditorModule,
  ],
  exports: [],
})
export class PagesModule {
}
