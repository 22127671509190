<div class="container px-0 pt-0 pb-2 bg-black">
  <div class="background">

    <picture>
      <source *ngFor="let width of imgWidths" [srcset]="imgPath + '/start/Alex-w' + width +'.webp'"
              media="(min-width: {{width}}px), (min-height: {{width/2}}px)">
      <img [src]="imgPath + '/start/Alex-w2000.webp'" alt="Alexander Schwartz start page background image" class="foreground-image"
           i18n-alt="Alt text of Alexander Schwartz start page background image">
    </picture>


    <mat-card class="my-services-card">
      <mat-card-title (click)="goToMyServices()" class="text-shadow-primary my-services-card-title hover-pointer" i18n>Meine Leistungen</mat-card-title>
      <ul>
        <li class="text-shadow-primary hover-pointer" i18n routerLink="software-development">Software-Entwicklung</li>
        <li class="text-shadow-primary hover-pointer" i18n routerLink="photography">Fotografie</li>
        <li class="text-shadow-primary hover-pointer" i18n routerLink="real-estate">Immobilieninvestments</li>
        <li class="text-shadow-primary hover-pointer" i18n routerLink="blitzshare">Blitzshare</li>
      </ul>
    </mat-card>

  </div>

  <div class="card-deck px-3" id="my-services">
    <div class="card service-card">
      <div class="service-card-cropped-image">
        <img [src]="imgPath + '/start/software-development.webp'" alt="Software-Entwicklung" class="top-rounded-image hover-pointer" i18n-alt
             routerLink="software-development">
      </div>
      <div class="card-body">
        <h5 class="card-title" i18n>Software-Entwicklung</h5>
        <p class="card-text">{{LEAD_SOFTWARE_DEVELOPER}}</p>
      </div>
      <div class="card-footer">
        <button class="w-100" color="primary" mat-flat-button routerLink="software-development">Mehr erfahren</button>
      </div>
    </div>

    <div class="card service-card">
      <img [src]="imgPath + '/start/photographer-w500.webp'" alt="Fotografie" class="top-rounded-image hover-pointer cropped-300h" i18n-alt
           routerLink="photography">
      <div class="card-body">
        <h5 class="card-title" i18n>Fotografie</h5>
        <p class="card-text">{{LEAD_PHOTOGRAPHY}}</p>
      </div>
      <div class="card-footer">
        <button class="w-100" color="primary" mat-flat-button routerLink="photography">Mehr erfahren</button>
      </div>
    </div>

    <div class="card service-card">
      <img [src]="imgPath + '/start/real-estate-w500.webp'" alt="Immobilien" class="top-rounded-image hover-pointer cropped-300h" i18n-alt
           routerLink="real-estate">
      <div class="card-body">
        <h5 class="card-title" i18n>Immobilien</h5>
        <p class="card-text">{{LEAD_REAL_ESTATE}}</p>
      </div>
      <div class="card-footer">
        <button class="w-100" color="primary" mat-flat-button routerLink="real-estate">Mehr erfahren</button>
      </div>
    </div>

    <div class="card service-card">
      <img [src]="imgPath + '/start/blitzshare-canoe-w500.webp'" alt="Blitzshare" class="top-rounded-image hover-pointer cropped-300h" i18n-alt
           routerLink="blitzshare">
      <div class="card-body">
        <h5 class="card-title" i18n>Blitzshare</h5>
        <p class="card-text">{{LEAD_BLITZSHARE}}</p>
      </div>
      <div class="card-footer">
        <button class="w-100" color="primary" mat-flat-button routerLink="blitzshare">Mehr erfahren</button>
      </div>
    </div>

  </div>

</div>
