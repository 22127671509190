import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-responsible-contact-details',
  templateUrl: './responsible-contact-details.component.html',
  styleUrls: ['./responsible-contact-details.component.scss'],
})
export class ResponsibleContactDetailsComponent implements OnInit {

  @Input() showTaxNumber = false;
  @Input() showBusinessInfo = false;
  @Input() showMessenger = false;
  @Input() showMap = false;
  @Input() lat = environment.homeLatitude;
  @Input() lng = environment.homeLongitude;

  constructor() {
  }

  ngOnInit(): void {
  }

}
