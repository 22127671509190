import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ImprintComponent} from '../pages/imprint/imprint.component';
import {PrivacyPolicyComponent} from '../pages/privacy-policy/privacy-policy.component';
import {SupportComponent} from '../pages/support/support.component';

const layoutRoutes: Routes = [
  {path: 'imprint', component: ImprintComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'support', component: SupportComponent},
];

@NgModule({
  imports: [RouterModule.forChild(layoutRoutes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {
}
