<div class="container p-2">

  <div class="jumbotron mb-3">
    <h1 class="jumbotron-headline" i18n="Contact headline">Kontakt</h1>
    <p class="jumbotron-lead" i18n="Contact lead text">Auf dieser Seite erfahren Sie, wie Sie mich kontaktieren können. Sie können mir auch direkt eine
      Nachricht über das Kontaktformular senden.</p>
  </div>

  <div class="row">
    <div class="col mb-3">
      <h1 class="paragraph-headline" i18n>Kontaktmöglichkeiten</h1>
      <p i18n>Sie erreichen mich schriftlich per Post, Email, telefonisch oder per Messenger:</p>
      <app-responsible-contact-details [showMap]="true" [showMessenger]="true"></app-responsible-contact-details>
    </div>

    <div class=" d-block d-xl-none w-100">
      <!-- This col ensures that the contact form is only shown, if the browser has a minimum width of xl (1200px) -->
    </div>

    <div class="col">
      <h1 class="paragraph-headline" i18n>Kontaktformular</h1>

      <p i18n>Sie können mir eine Nachricht über das folgende Kontaktformular schicken:</p>

      <form (ngSubmit)="onSendMessage()" *ngIf="!messageSent" [formGroup]="messageForm">
        <div class="row">
          <div class="col">
            <mat-form-field class="mat-form-input text-input-long">
              <mat-label i18n="your name">Ihr Name</mat-label>
              <input #senderNameRef [maxlength]="senderNameMaxLength" formControlName="senderName" matInput
                     required>
              <mat-error *ngIf="!messageForm.get('senderName')?.valid && messageForm.get('senderName')?.touched" i18n>Bitte geben Sie Ihren Namen ein.
              </mat-error>
              <span class="character-counter">{{senderNameRef.value.length}}/{{senderNameMaxLength}}</span>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="mat-form-input text-input-long">
              <mat-label i18n="your email">Ihre Email-Adresse</mat-label>
              <input #senderEmailRef [maxlength]="senderEmailMaxLength" formControlName="senderEmail" matInput
                     required>
              <mat-error *ngIf="messageForm.get('senderEmail')?.hasError('required') && messageForm.get('senderEmail')?.touched" i18n>Bitte geben Sie Ihre
                Email-Adresse ein.
              </mat-error>
              <mat-error *ngIf="messageForm.get('senderEmail')?.hasError('email') && messageForm.get('senderEmail')?.touched" i18n>Die eingegebene Emailadresse
                ist
                ungültig.
              </mat-error>
              <span class="character-counter">{{senderEmailRef.value.length}}/{{senderEmailMaxLength}}</span>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="mat-form-input text-input-long">
              <mat-label i18n="your phonenumber">Ihre Telefonnummer</mat-label>
              <input #senderPhoneRef [maxlength]="senderPhoneMaxLength" formControlName="senderPhone"
                     matInput>

              <mat-error *ngIf="!messageForm.get('senderPhone')?.valid && messageForm.get('senderPhone')?.touched" i18n>Das Format Ihrer Telefonnummer ist
                ungültig.
                Bitte geben Sie Ihre Nummer mit Landesvorwahl ein, z.B. +491601234567.
              </mat-error>
              <span class="character-counter">{{senderPhoneRef.value.length}}/{{senderPhoneMaxLength}}</span>
            </mat-form-field>
          </div>
          <div class="col">
            <mat-form-field class="mat-form-input text-input-long">
              <mat-label i18n>Kategorie</mat-label>
              <mat-select #categoryRef formControlName="categoryId" id="category" panelClass="mat-select-panel-500px">
                <mat-option [value]="null" i18n></mat-option>
                <mat-option *ngFor="let mesCat of messageCategories" [value]="mesCat.id">{{mesCat.value}}</mat-option>
              </mat-select>
              <mat-error *ngIf="!messageForm.get('category')?.valid && messageForm.get('category')?.touched" i18n>Please select a category.</mat-error>
            </mat-form-field>
          </div>
        </div>

        <mat-form-field class="mat-form-input text-input-100">
          <mat-label i18n="email subject">Betreff</mat-label>
          <input #subjectRef [maxlength]="subjectMaxLength" formControlName="subject" i18n-placeholder matInput placeholder="Bitte geben Sie einen Betreff ein."
                 required type="text">
          <mat-error *ngIf="!messageForm.get('subject')?.valid && messageForm.get('subject')?.touched" i18n>Bitte geben Sie einen Betreff ein.</mat-error>
          <span class="character-counter">{{subjectRef.value.length}}/{{subjectMaxLength}}</span>
        </mat-form-field>

        <div class="NgxEditor__Wrapper editor mb-3">

          <div class="editor-toolbar-three-rows">
            <ngx-editor-menu [editor]="editor" [toolbar]="toolbarSm1"></ngx-editor-menu>
            <ngx-editor-menu [editor]="editor" [toolbar]="toolbarSm2"></ngx-editor-menu>
            <ngx-editor-menu [editor]="editor" [toolbar]="toolbarSm3"></ngx-editor-menu>
          </div>
          <div class="editor-toolbar-two-rows">
            <ngx-editor-menu [editor]="editor" [toolbar]="toolbarMd1"></ngx-editor-menu>
            <ngx-editor-menu [editor]="editor" [toolbar]="toolbarMd2"></ngx-editor-menu>
          </div>
          <div class="editor-toolbar-one-row">
            <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
          </div>
          <ngx-editor-floating-menu [editor]="editor">
            <ngx-editor-menu [editor]="editor" [toolbar]="toolbar"></ngx-editor-menu>
          </ngx-editor-floating-menu>
          <div [class]="editorExpanded ? 'editor-container-full' : 'editor-container'">
            <ngx-editor [editor]="editor" formControlName="message"
                        i18n-placeholder="message input placeholder" maxlength="{{messageMaxLength}}" outputFormat="html"
                        placeholder="Bitte geben Sie eine Nachricht ein."></ngx-editor>
          </div>
          <div class="character-counter"><span class="show-expand-editor-link">
                  <a (click)="editorExpanded = false" *ngIf="editorExpanded" class="hover-pointer" i18n>Editor verkleinern</a>
                  <a (click)="editorExpanded = true" *ngIf="!editorExpanded" class="hover-pointer" i18n>Editor vergrößern</a>
                  - </span><span>{{messageForm?.value?.message?.length ? messageForm.value.message.length : 0}}
            /{{messageMaxLength}}</span>
          </div>
        </div>
        <mat-error *ngIf="messageForm?.value?.message?.length < messageMinLength && messageForm.get('message')?.touched" class="mt-2 small"
                   i18n="error message too long">Bitte geben Sie
          mindestens {{messageMinLength}} ein.
        </mat-error>
        <mat-error *ngIf="messageForm?.value?.message?.length > messageMaxLength" class="mt-2 small" i18n="error message too long">Bitte geben Sie
          höchstens {{messageMaxLength}} ein. Die Formatierung verbraucht auch Zeichen.
        </mat-error>
        <br>

        <app-info-card icon="error_outline">Ihre Nachricht wird unverschlüsselt per Email gesendet.</app-info-card>

        <br>

        <app-mat-icon-button
          [disabled]="!messageForm?.valid" color="primary" cssClass="mb-3 mt-2" i18n-label="Send message" icon="message" label="Nachricht senden"
          type="submit"></app-mat-icon-button>
      </form>
      <app-alerts [alerts]="alerts" [float]="false"></app-alerts>
      <app-loading-spinner *ngIf="showLoadingSpinner" [centered]="true" [float]="false" [message]="loadingSpinnerMessage"
                           [messagesByKey]="loadingSpinnerMessagesByKey"></app-loading-spinner>

      <button (click)="resetForm()" *ngIf="messageSent" class="mt-3" color="primary" mat-flat-button>Eine weitere Nachricht senden</button>

    </div>
  </div>

</div>
