<div class="container p-2">

  <!-- On small screens, show vertical layout (text and button below image) -->
  <div class="card w-100 mb-3 d-block d-md-none">
    <div class="row no-gutters">
      <div class="col">
        <div class="jumbotron-cropped-image-w100">
          <img [src]="titleImage" alt="Immobilien" class="top-rounded-image" i18n-alt>
        </div>
      </div>
      <div class="col p-3">
        <ng-container *ngTemplateOutlet="jumbotronContent"></ng-container>
      </div>
    </div>
  </div>

  <!-- On larger screens, show horizontal layout (text and button next to image) -->
  <div class="card w-100 mb-3 d-md-block d-none">
    <div class="row no-gutters">
      <img [src]="imgPathRealEstate + 'real-estate-w600.webp'" alt="Immobilien" class="jumbotron-cropped-image-w300px top-rounded-image" i18n-alt>
      <div class="col">
        <div class="card-body">
          <ng-container *ngTemplateOutlet="jumbotronContent"></ng-container>
        </div>
      </div>
    </div>
  </div>
  <ng-template #jumbotronContent>
    <h1 class="jumbotron-headline" i18n>Immobilien</h1>
    <p class="jumbotron-lead">{{LEAD_REAL_ESTATE}}</p>

    <a [queryParams]="{categoryId: 'real-estate'}" routerLink="/contact">
      <button color="primary" mat-flat-button>Kontaktieren Sie mich mit Ihrem Angebot!</button>
    </a>
  </ng-template>

  <h1 class="paragraph-headline" i18n>Immobilienstrategie</h1>

  <p i18n>Ich kaufe günstige Immobilien in D-Lagen (kleine bis mittelgroße Städte), die häufig renovierungsbedürftig bis unbewohnbar sind. Diese renoviere ich
    dann und schaffe so neuen Wohnraum.</p>
  <p i18n>Die Mieteinnahmen nutze ich dabei für folgende Dinge:</p>
  <ol>
    <li i18n>Rückzahlung des Bankdarlehens</li>
    <li i18n>Aufbau von Rücklagen für laufende Instandhaltung und Renovierungen</li>
    <li i18n>Reinvestition in weitere Objekte</li>
  </ol>
  <p i18n></p>
  <p i18n></p>


  <h1 class="paragraph-headline" i18n>Wollen Sie ein Objekt verkaufen?</h1>

  <p i18n>Wenn Sie eine Wohnung, ein Einfamilienhaus oder ein Mehrfamilienhaus verkaufen möchten, <a [queryParams]="{categoryId: 'real-estate'}"
                                                                                                     routerLink="/contact">melden Sie sich</a>
    gern bei mir. Ich bin
    stets interessiert an neuen Objekten - vor allem an solchen, die noch nicht auf den großen Immobilienplattformen gelistet sind.</p>

  <h1 class="paragraph-headline" i18n>Objekte aus meinem Portfolio</h1>

  <!-- Image -->
  <div *ngIf="imgUrlsObject" class="mb-3">
    <ngb-carousel #imageCarouselObject *ngIf="imgUrlsObject.length>0" [interval]="0" id="carouselObject">
      <ng-template (slid)="activeImageIndexObject = iObject" *ngFor="let imgUrl of imgUrlsObject; index as iObject" id="{{iObject}}" ngbSlide>
        <div class="wrapper">
          <picture>
            <source [srcset]="imgUrl.w350" media="(max-width: 350px)">
            <source [srcset]="imgUrl.w500" media="(max-width: 500px)">
            <source [srcset]="imgUrl.w750" media="(max-width: 750px)">
            <img (click)="openImage(iObject, imgUrlsObject)"
                 *ngIf="isImageActive(iObject,  'carouselObject')" [alt]="imgUrl.title" [src]="imgUrl.w1124">
          </picture>


        </div>
      </ng-template>
    </ngb-carousel>
    <br>
    <div class="thumbnail-container m-0 p-0">
      <app-thumbnail-widget (imgClicked)="selectImage($event.index, 'carouselObject')" [imgUrls]="imgUrlsObject"></app-thumbnail-widget>
    </div>
  </div>


</div>
