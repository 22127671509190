import {Action, ActionReducer, createReducer} from '@ngrx/store';
import {environment} from '../../../environments/environment';


export interface State {
}

export const initialState: State = {};

function logDebugMessages(actionName: string, state: State, newState: any): void {
  if (environment.enableReducerLogging) {
    console.log((new Date()).toLocaleString() + ': ' + actionName);
    console.log(state);
    console.log(newState);
  }
}

const reducer: ActionReducer<State, Action> = createReducer(
  initialState,
);

export function layoutReducer(state: State | undefined, action: Action): State {
  return reducer(state, action);
}

