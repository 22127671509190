import {User} from '../models/user.interface';
import {UserPublic} from '../models/userPublic.interface';
import firebase from 'firebase/app';
// for cloud firestore
import {Currency} from '../models/currency.interface';
import DocumentData = firebase.firestore.DocumentData;


export function convertToUser(data: DocumentData): User {
  if (!data?.uid || !data.displayName)
    throw new Error(`Invalid user. Data is missing: data?.uid=${data?.uid}, data.displayName=${data.displayName}`);

  const user: User = {
    creationDate: data.creationDate,
    uid: data.uid,
    displayName: data.displayName,
    ...data,
  };
  if (data.address)
    user.address = data.address;
  if (data.firstName)
    user.firstName = data.firstName;
  if (data.lastName)
    user.lastName = data.lastName;
  if (data.telephoneNumber)
    user.telephoneNumber = data.telephoneNumber;
  if (data.admin)
    user.admin = data.admin;
  if (data.email)
    user.email = data.email;
  if (data.lastEditDate)
    user.lastEditDate = data.lastEditDate;
  if (data.photoURL)
    user.photoURL = data.photoURL;
  return user;
}

export function convertToUserPublic(data: DocumentData): UserPublic {
  if (!data?.uid || !data.displayName)
    throw new Error(`Invalid user. Data is missing: data?.uid=${data?.uid}, data.displayName=${data.displayName}`);

  const userPublic: UserPublic = {
    creationDate: data.creationDate,
    uid: data.uid,
    displayName: data.displayName,
    ...data,
  };
  if (data.imgUrl)
    userPublic.imgUrl = data.imgUrl;
  if (data.imgUrlThumb)
    userPublic.imgUrlThumb = data.imgUrlThumb;
  return userPublic;
}

export function convertToCurrency(data: DocumentData): Currency {
  if (!data?.id || !data?.identifier || !data?.symbol || !data?.strings)
    throw new Error(`Invalid conversation. Data is missing: data?.id=${data?.id}, data?.strings=${data?.strings}, data?.symbol=${data?.symbol}, data?.identifier=${data?.identifier}`);

  const currency: Currency = {
    id: data.id,
    identifier: data.identifier,
    strings: data.strings,
    symbol: data.symbol,
    ...data,
  };
  return currency;
}
