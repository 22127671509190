import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LayoutModule} from './layout/layout.module';
import {CoreModule} from './core.module';
import {StoreModule} from '@ngrx/store';
import * as fromApp from './store/app.reducer';
import {EffectsModule} from '@ngrx/effects';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {SharedEffects} from './shared/store/shared.effects';
import {DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';

import {registerLocaleData} from '@angular/common';
import localePTDE from '@angular/common/locales/de';
import {CustomDateAdapter} from './config/custom-date-adapter';
import * as moment from 'moment';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {SharedModule} from './shared/shared.module';
import {NgxAuthFirebaseUIModule} from 'ngx-auth-firebaseui';
import firebase from 'firebase/app';

import 'firebase/auth'; // for authentication
import 'firebase/storage'; // for storage
import 'firebase/firestore'; // for cloud firestore
import {AgmCoreModule, GoogleMapsAPIWrapper} from '@agm/core';
import {NgCacheRouteReuseModule} from 'ng-cache-route-reuse';
import {NgxEditorModule} from 'ngx-editor';
import {LayoutEffects} from './layout/store/layout.effects';
import Locale from './shared/services/locale';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService} from '@angular/fire/analytics';
import {AngularFireModule} from '@angular/fire';
import {PagesModule} from './pages/pages.module'; // for maps integration

registerLocaleData(localePTDE);

firebase.initializeApp(environment.firebaseConfig);
firebase.firestore().settings({experimentalForceLongPolling: true});
export const firestore = firebase.firestore();
export const firebaseStorage = firebase.storage();

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
    BrowserAnimationsModule,
    StoreModule.forRoot(fromApp.appReducer, {metaReducers: fromApp.metaReducers}),
    EffectsModule.forRoot([LayoutEffects, SharedEffects]),
    AgmCoreModule.forRoot({
      apiKey: environment.geoCodingApiKey,
      libraries: ['places'],
    }),
    NgbModule,
    MatNativeDateModule,
    LayoutModule,
    PagesModule,
    SharedModule,
    HammerModule,
    NgxEditorModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,

    // Specify the ngx-auth-firebaseui library as an import
    NgxAuthFirebaseUIModule.forRoot({
      apiKey: environment.firebaseConfig.apiKey,
      authDomain: environment.firebaseConfig.authDomain,
      databaseURL: environment.firebaseConfig.databaseURL,
      projectId: environment.firebaseConfig.projectId,
      storageBucket: environment.firebaseConfig.storageBucket,
      messagingSenderId: environment.firebaseConfig.messagingSenderId,
      measurementId: environment.firebaseConfig.measurementId,
    }),
    NgCacheRouteReuseModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    MatTooltipModule,
  ],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: Locale.dateAdapterLocale()},
    {provide: DateAdapter, useClass: CustomDateAdapter},
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
    GoogleMapsAPIWrapper,
    ScreenTrackingService,
    UserTrackingService,
    // AngularFireStorage,
    // AngularFirestore,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private dateAdapter: DateAdapter<Date>) {
    // Set the locales
    this.setLocales();
    // When the page is opened with just the domain name (without '/de' or '/en', the momentLocale cannot be determined. Therefore, wait a little, and set them again
    setTimeout(args => this.setLocales(), 100);
  }

  private setLocales() {
    moment.locale(Locale.momentLocale());
    this.dateAdapter.setLocale(Locale.dateAdapterLocale());
  }
}
