<div class="container p-2">

  <!-- On small screens, show vertical layout (text and button below image) -->
  <div class="card w-100 mb-3 d-block d-md-none">
    <div class="row no-gutters">
      <div class="col">
        <div class="jumbotron-cropped-image-w100">
          <img [src]="titleImage" alt="Fotografie" class="top-rounded-image" i18n-alt>
        </div>
      </div>
      <div class="col p-3">
        <ng-container *ngTemplateOutlet="jumbotronContent"></ng-container>
      </div>
    </div>
  </div>

  <!-- On larger screens, show horizontal layout (text and button next to image) -->
  <div class="card w-100 mb-3 d-md-block d-none">
    <div class="row no-gutters">
      <img [src]="imgPathPhotography + 'photographer-w600.webp'" alt="Fotografie" class="jumbotron-cropped-image-w300px top-rounded-image" i18n-alt>
      <div class="col">
        <div class="card-body">
          <ng-container *ngTemplateOutlet="jumbotronContent"></ng-container>
        </div>
      </div>
    </div>
  </div>
  <ng-template #jumbotronContent>
    <h1 class="jumbotron-headline" i18n>Fotografie</h1>
    <p class="jumbotron-lead">{{LEAD_PHOTOGRAPHY}}</p>
    <a [queryParams]="{categoryId: 'photography'}" routerLink="/contact">
      <button color="primary" mat-flat-button>Kontaktieren Sie mich mit Ihrem Auftrag!</button>
    </a>
  </ng-template>

  <h1 class="paragraph-headline" i18n>Beispielbilder</h1>
  <h2 class="paragraph-headline-2" i18n>Natur und Architektur</h2>

  <!-- Image -->
  <div *ngIf="imgUrlsNature" class="mb-3">
    <ngb-carousel #imageCarouselNature *ngIf="imgUrlsNature.length>0" [interval]="0" id="carouselNature">
      <ng-template (slid)="activeImageIndexNature = iNature" *ngFor="let imgUrl of imgUrlsNature; index as iNature" id="{{iNature}}" ngbSlide>
        <div class="wrapper">
          <picture>
            <source [srcset]="imgUrl.w350" media="(max-width: 350px)">
            <source [srcset]="imgUrl.w500" media="(max-width: 500px)">
            <source [srcset]="imgUrl.w750" media="(max-width: 750px)">
            <img (click)="openImage(iNature, imgUrlsNature)"
                 *ngIf="isImageActive(iNature,  'carouselNature')" [alt]="imgUrl.title" [src]="imgUrl.w1124">
          </picture>


        </div>
      </ng-template>
    </ngb-carousel>
    <br>
    <div class="thumbnail-container m-0 p-0">
      <app-thumbnail-widget (imgClicked)="selectImage($event.index, 'carouselNature')" [imgUrls]="imgUrlsNature"></app-thumbnail-widget>
    </div>
  </div>


  <h2 class="paragraph-headline-2" i18n>Eventfotografie</h2>

  <!-- Image -->
  <div *ngIf="imgUrlsEvent" class="mb-3">
    <ngb-carousel #imageCarouselEvent *ngIf="imgUrlsEvent.length>0" [interval]="0" id="carouselEvent">
      <ng-template (slid)="activeImageIndexEvent = iEvent" *ngFor="let imgUrl of imgUrlsEvent; index as iEvent" id="{{iEvent}}" ngbSlide>
        <div class="wrapper">
          <picture>
            <source [srcset]="imgUrl.w350" media="(max-width: 350px)">
            <source [srcset]="imgUrl.w500" media="(max-width: 500px)">
            <source [srcset]="imgUrl.w750" media="(max-width: 750px)">
            <img (click)="openImage(iEvent, imgUrlsEvent)"
                 *ngIf="isImageActive(iEvent,'carouselEvent')" [alt]="imgUrl.title" [src]="imgUrl.w1124">
          </picture>


        </div>
      </ng-template>
    </ngb-carousel>
    <br>
    <div class="thumbnail-container m-0 p-0">
      <app-thumbnail-widget (imgClicked)="selectImage($event.index, 'carouselEvent')" [imgUrls]="imgUrlsEvent"></app-thumbnail-widget>
    </div>
  </div>

</div>
