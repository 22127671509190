import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../shared/services/user.service';
import {TitleService} from '../../shared/services/title.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent extends BaseComponent implements OnInit {

  constructor(
    authService: AuthService,
    userService: UserService,
    private titleService: TitleService) {
    super(authService, userService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Support`);
  }


}
