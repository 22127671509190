<div class="bg-primary">
  <div class="container p-0">
    <mat-toolbar color="primary">
      <a class="navbar-brand" i18n-matTooltip matTooltip="Startseite" routerLink="/" style="cursor: pointer;">
        <!--        <img alt="aSchwartz.de Logo"-->
        <!--             class="align-top img-fluid app-icon d-block d-xs-none"-->
        <!--             i18n-alt="aSchwartz.de logo alt text" sizes="(max-width: 150px) 150px, 200px"-->
        <!--             src="./assets/icons/icon-72x72.png"-->
        <!--             srcset="./assets/icons/icon-72x72.png 72w, ./assets/icons/icon-128x128.png 128w">-->

        <picture>
          <source media="(max-width: 150px)" srcset="./assets/images/logos/logo-w150.webp">
          <source media="(max-width: 200px)" srcset="./assets/images/logos/logo-w200.webp">
          <source media="(max-width: 300px)" srcset="./assets/images/logos/logo-w300.webp">
          <img alt="Alexander Schwartz logo" class="align-top img-fluid app-logo" i18n-alt="Alt text of Alexander Schwartz logo"
               src="./assets/images/logos/logo-w500.webp">
        </picture>
      </a>

      <a class="d-none d-xs-block" color="accent" i18n="software development header button text" mat-button routerLink="/software-development"
         routerLinkActive="active">Software-Entwicklung</a>
      <a class="d-none d-sm-block" color="accent" i18n="photography header link text" mat-button routerLink="/photography"
         routerLinkActive="active">Fotografie</a>
      <a class="d-none d-md-block" color="accent" i18n="real estate header link text" mat-button routerLink="/real-estate"
         routerLinkActive="active">Immobilien</a>
      <a class="d-none d-md-block" color="accent" i18n="Blitzshare header link text" mat-button routerLink="/blitzshare"
         routerLinkActive="active">Blitzshare</a>

      <mat-menu #menu="matMenu">
        <a i18n="software development header button text" mat-menu-item routerLink="/software-development"
           routerLinkActive="active">Software Entwicklung</a>
        <a i18n="photography header link text" mat-menu-item routerLink="/photography"
           routerLinkActive="active">Fotografie</a>
        <a i18n="real estate header link text" mat-menu-item routerLink="/real-estate"
           routerLinkActive="active">Immobilien</a>
        <a i18n="Blitzshare header link text" mat-menu-item routerLink="/blitzshare"
           routerLinkActive="menu-active">Blitzshare</a>

      </mat-menu>

      <span class="spacer"></span>
      <button [matMenuTriggerFor]="menu" aria-label="Menu" class="d-block d-md-none" i18n-aria-label="Menu" mat-icon-button>
        <mat-icon>menu</mat-icon>
      </button>

      <div class="d-none d-lg-block name-email-container">
        <a class="name p-0 m-0" i18n-matTooltip matTooltip="Kontakt" routerLink="./contact">Alexander Schwartz<br></a>
        <a class="email p-0 m-0" href="mailto:{{contactMail}}" i18n-matTooltip matTooltip="Email senden">{{contactMail}}</a>
      </div>
      <a i18n-matTooltip matTooltip="Kontakt" routerLink="./contact"><img alt="Bild von Alexander Schwartz" class="avatar d-none d-md-block ml-2"
                                                                          i18n-alt="header avatar alt text" src="./assets/images/header-avatar.webp"></a>

    </mat-toolbar>

  </div>

</div>
