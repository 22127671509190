import {Component, OnInit, ViewChild} from '@angular/core';
import {LEAD_PHOTOGRAPHY} from '../../shared/constants/strings';
import {environment} from '../../../environments/environment';
import {ImageCarouselModalContentComponent} from '../../shared/components/image-carousel-modal-content/image-carousel-modal-content.component';
import {ImgUrls} from '../../shared/models/imgUrls.interface';
import {NgbCarousel, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import Util from '../../shared/util';
import {UtilService} from '../../shared/util.service';
import {MetadataService} from '../../shared/services/metadata.service';
import {TitleService} from '../../shared/services/title.service';

const PHOTO_COUNT_NATURE = 71;
const PHOTO_COUNT_EVENT = 42;

@Component({
  selector: 'app-photography',
  templateUrl: './photography.component.html',
  styleUrls: ['./photography.component.scss'],
})
export class PhotographyComponent implements OnInit {

  activeImageIndexNature = 0;
  activeImageIndexEvent = 0;
  imgPath = environment.storageImgPath;
  imgPathPhotography = this.imgPath + '/photography/';
  titleImage = this.imgPath + '/start/photographer-w500.webp';
  imgUrlsNature: ImgUrls[] = [];
  imgUrlsEvent: ImgUrls[] = [];

  @ViewChild('imageCarouselNature') imageCarouselNature: NgbCarousel | undefined;
  @ViewChild('imageCarouselEvent') imageCarouselEvent: NgbCarousel | undefined;

  constructor(
    private modalService: NgbModal,
    private metadataService: MetadataService,
    private utilService: UtilService,
    private titleService: TitleService) {
  }

  public get LEAD_PHOTOGRAPHY() {
    return LEAD_PHOTOGRAPHY;
  }

  ngOnInit(): void {
    this.titleService.setTitle($localize`Fotografie`);
    this.metadataService.updateTags($localize`Fotografie`,
      $localize`Hier erfahren Sie alles über meine Leistungen als Fotograf. Ich fotografiere sowohl Naturereignisse als auch Events.`,
      $localize`Fotografie natur tiere events hochzeiten partys`,
      [this.titleImage],
    );
    this.createImgUrls(1, PHOTO_COUNT_NATURE, 'carouselNature');
    this.createImgUrls(1, PHOTO_COUNT_EVENT, 'carouselEvent');
  }


  /**
   * Opens the given array of images in a carousel inside a modal.
   * @param selectedId the ID of the image to be selected at the beginning
   * @param imgUrls all images to be shown in the carousel
   */
  openImage(selectedId: number, imgUrls: ImgUrls[]): void {
    const modalRef = this.modalService.open(ImageCarouselModalContentComponent, {
      windowClass: 'myCustomModalClass',
    });
    modalRef.componentInstance.selectedId = selectedId;
    modalRef.componentInstance.imgUrls = imgUrls;
    modalRef.componentInstance.altName = imgUrls[selectedId].title;
  }

  isImageActive(i: number, carousel: 'carouselNature' | 'carouselEvent') {
    switch (carousel) {
      case 'carouselNature':
        return Util.isSlideActive(i, this.activeImageIndexNature, this.imgUrlsNature!.length);
      case 'carouselEvent':
        return Util.isSlideActive(i, this.activeImageIndexEvent, this.imgUrlsEvent!.length);
    }

  }


  selectImage(index: number, carousel: 'carouselNature' | 'carouselEvent') {
    let imageCarousel;
    switch (carousel) {
      case 'carouselNature':
        imageCarousel = this.imageCarouselNature;
        break;
      case 'carouselEvent':
        imageCarousel = this.imageCarouselEvent;
    }
    if (!imageCarousel?.activeId)
      return;
    imageCarousel.select('' + index);
    this.utilService.scrollToId(carousel);
  }

  private createImgUrls(firstIndex: number, lastIndex: number, carousel: 'carouselNature' | 'carouselEvent') {
    switch (carousel) {
      case 'carouselNature':
        this.imgUrlsNature = [];
        for (let i = firstIndex; i <= lastIndex; i++)
          this.imgUrlsNature.push(
            {
              w150: this.imgPathPhotography + `photo${i}-w150.webp`,
              w350: this.imgPathPhotography + `photo${i}-w350.webp`,
              w500: this.imgPathPhotography + `photo${i}-w500.webp`,
              w750: this.imgPathPhotography + `photo${i}-w750.webp`,
              w1124: this.imgPathPhotography + `photo${i}-w1124.webp`,
              w1500: this.imgPathPhotography + `photo${i}-w1500.webp`,
              full: this.imgPathPhotography + `photo${i}-w2000.webp`,
              title: $localize`Naturfoto ${i}`,
            });
        Util.shuffle(this.imgUrlsNature);
        break;
      case 'carouselEvent':
        this.imgUrlsEvent = [];
        for (let i = firstIndex; i <= lastIndex; i++)
          this.imgUrlsEvent.push(
            {
              w150: this.imgPathPhotography + `event${i}-w150.webp`,
              w350: this.imgPathPhotography + `event${i}-w350.webp`,
              w500: this.imgPathPhotography + `event${i}-w500.webp`,
              w750: this.imgPathPhotography + `event${i}-w750.webp`,
              w1124: this.imgPathPhotography + `event${i}-w1124.webp`,
              w1500: this.imgPathPhotography + `event${i}-w1500.webp`,
              full: this.imgPathPhotography + `event${i}-w2000.webp`,
              title: $localize`Eventfoto ${i}`,
            });
        Util.shuffle(this.imgUrlsEvent);
    }

  }
}
