import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../shared/services/user.service';
import {TitleService} from '../../shared/services/title.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent extends BaseComponent implements OnInit {

  currentUrl?: string;

  constructor(
    authService: AuthService,
    userService: UserService,
    private router: Router,
    private titleService: TitleService) {
    super(authService, userService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`404 - Seite nicht gefunden`);
    this.currentUrl = this.router.url;
  }


}
