import {Component, Input, OnInit} from '@angular/core';

/**
 * Material button, which contains a material icon.
 * Usage example:
 *
 *   <app-mat-icon-button [callback]="deleteExcludedDate.bind(this)" [params]="[availabilityIndex, excludedDateIndex]"
 *                        color="warn" i18n-label="Button label delete excluded date" icon="delete"
 *                        label="Delete this excluded date"></app-mat-icon-button>
 *
 * The params for the callback method can be passed as an array to the params input object.
 */
@Component({
  selector: 'app-mat-icon-button',
  templateUrl: './mat-icon-button.component.html',
  styleUrls: ['./mat-icon-button.component.css'],
})
export class MatIconButtonComponent implements OnInit {

  @Input() callback?: ((args?: any, data?: any) => void);
  @Input() params: any[] = [];
  @Input() data?: any;
  @Input() color = 'primary';
  @Input() icon?: string;
  @Input() label ?: string;
  @Input() cssClass?: string;
  @Input() disabled = false;
  @Input() type?: string;


  constructor() {
  }

  ngOnInit(): void {
  }

  onClick(event?: Event): void {
    if (this.callback)
      this.callback(...this.params);
  }
}
