import {Injectable} from '@angular/core';
import * as fromApp from '../store/app.reducer';
import {Store} from '@ngrx/store';
import {BehaviorSubject, Subject} from 'rxjs';

import firebase from 'firebase/app';
import {SharedService} from '../shared/services/shared.service';
import {Router} from '@angular/router';
import {UserService} from '../shared/services/user.service';
import {AnalyticsEventName, AnalyticsService} from '../shared/services/analytics.service';
import User = firebase.User;


@Injectable({
  providedIn: 'root',
})
export class AuthService {

  tokenExpirationTimer: any | undefined;

  openLoginDropdownSubject = new Subject<boolean>();
  userSubject = new BehaviorSubject<User | undefined>(undefined);
  private isFirebaseLoggedIn = false;

  constructor(private store: Store<fromApp.AppState>,
              private router: Router,
              private userService: UserService,
              private sharedService: SharedService,
              private analyticsService: AnalyticsService) {
  }


  /**
   * Emits an event on the openLoginDropdownSubject, if available, and returns true. Otherwise returns false.
   * @return true, if successful, false otherwise
   */
  openLoginDropdown(): boolean {
    if (this.openLoginDropdownSubject) {
      this.openLoginDropdownSubject.next(true);
      return true;
    }
    return false;
  }

  /**
   * Called on successful firebase login.
   * @param user firebase user
   */
  onFirebaseSignIn(user: User): void {
    this.userSubject.next(user);
    this.isFirebaseLoggedIn = true;
    this.analyticsService.logEvent(AnalyticsEventName.SIGN_IN);

  }

  /**
   * Called on firebase logout.
   */
  onFirebaseLogout(): void {
    this.userService.clearUserCache();
    this.userSubject.next(undefined);
    console.log('Logging out from firebase.');
    this.analyticsService.logEvent(AnalyticsEventName.SIGN_OUT);
    if (this.isFirebaseLoggedIn) {
      this.router.navigate(['loggedOut']);
      this.isFirebaseLoggedIn = false;
    }
  }
}
