import {Injectable, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {State} from '../store/shared.reducer';
import {Store} from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import {CurLangStrings, Currency} from '../models/currency.interface';
import {environment} from '../../../environments/environment';
import Locale from './locale';
import {Wrapper} from '../models/wrapper.model';
import {takeUntil} from 'rxjs/operators';
import {firestore} from '../../app.module';
import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from '@angular/fire/firestore';
import {convertToCurrency} from '../converters/modelConverters';
import {clearCurrenciesCache, setCurrencies} from '../store/shared.actions';
import {Mutex} from 'async-mutex';

@Injectable({
  providedIn: 'root',
})
export class CurrencyService implements OnDestroy {
  destroy$: Subject<null> = new Subject();

  currencyMutex = new Mutex();
  currencies: Currency[] = [];
  currenciesById: Map<string, Currency> = new Map<string, Currency>();
  sharedObservable: Observable<State>;
  private lastFetch?: Date;

  constructor(private store: Store<fromApp.AppState>) {
    this.sharedObservable = this.store.select(state => state.shared);
    this.init();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }


  /**
   * Delivers all currencies (as a tree) either from the cache, if there is an up-to-date cache, or from the firestore.
   */
  async getCurrencies(): Promise<Wrapper<Currency[]>> {
    return this.currencyMutex.runExclusive(async () => {
      if (!this.currencies || this.currencies.length === 0 || this.cacheIsOutdated()) {
        // Fetch live currencies
        const wrapper = await this.fetchCurrencies();
        if (wrapper.errorMessage) {
          console.log(`Error loading currencies: ${wrapper.errorMessage}`);
        }
        if (wrapper.data) {
          this.currencies = wrapper.data;
          this.store.dispatch(setCurrencies({currencies: this.currencies, currenciesFetchDate: new Date()}));
        }
        return wrapper;
      }

      // Return currencies from cache
      const cacheWrapper: Wrapper<Currency[]> = {data: this.currencies};
      return cacheWrapper;
    });
  }

  /**
   * Delivers all currencies as a linear map either from the cache, if there is an up-to-date cache, or from the firestore.
   */
  async getCurrenciesById(): Promise<Wrapper<Map<string, Currency>>> {
    return this.currencyMutex.runExclusive(async () => {
      if (!this.currenciesById || this.currenciesById.size === 0 || this.cacheIsOutdated()) {
        // Fetch live currencies
        const wrapper = await this.getCurrencies();
        if (wrapper.data) {
          this.currenciesById = this.createCurrenciesMap(wrapper.data);
          return {data: this.currenciesById};
        } else if (wrapper.errorMessage)
          return {errorMessage: wrapper.errorMessage};
      }

      // Return currencies from cache
      const cacheWrapper: Wrapper<Map<string, Currency>> = {data: this.currenciesById};
      return cacheWrapper;
    });
  }

  /**
   * Delivers the currency lang string name0 from the given currency. If the globally set locale, environment.firestoreLocale, is not
   * available, the fallback environment.defaultFirestoreLocale is used.
   * @param currency currency, from and for which the lang strings should be delivered
   * @return language string name0
   */
  getCurrencyName0(currency: Currency): string {
    const strings = this.getCurrencyLangStrings(currency);
    return strings.name0;
  }

  /**
   * Delivers the currency lang string name1 from the given currency. If the globally set locale, environment.firestoreLocale, is not
   * available, the fallback environment.defaultFirestoreLocale is used.
   * @param currency currency, from and for which the lang strings should be delivered
   * @return language string name1
   */
  getCurrencyName1(currency: Currency): string {
    const strings = this.getCurrencyLangStrings(currency);
    return strings.name1;
  }

  /**
   * Delivers the currency lang string nameN from the given currency. If the globally set locale, environment.firestoreLocale, is not
   * available, the fallback environment.defaultFirestoreLocale is used.
   * @param currency currency, from and for which the lang strings should be delivered
   * @return language string nameN
   */
  getCurrencyNameN(currency: Currency): string {
    const strings = this.getCurrencyLangStrings(currency);
    return strings.nameN;
  }

  /**
   * Delivers the currency lang strings (name0, name1, nameN) from the given currency. If the globally set locale, environment.firestoreLocale, is not
   * available, the fallback environment.defaultFirestoreLocale is used.
   * @param currency currency, from and for which the lang strings should be delivered
   * @return language strings
   */
  getCurrencyLangStrings(currency: Currency): CurLangStrings {
    const strings = currency.strings[Locale.firestoreLocale()];
    return strings ? strings : currency.strings[environment.defaultFirestoreLocale];
  }


  init(): void {
    this.sharedObservable.pipe(takeUntil(this.destroy$)).subscribe(state => {
      if (state.currencies) {
        this.currencies = state.currencies;
      }
      if (state.currenciesFetchDate)
        this.lastFetch = state.currenciesFetchDate;
    });
  }

  /**
   * Fetches currencies directly from the firestore
   */
  async fetchCurrencies(): Promise<Wrapper<Currency[]>> {
    try {
      const query = firestore.collection(environment.firestoreCollectionCurrencies).withConverter(currencyConverter);
      const currencyQuerySnapshot = await query.get();
      const currencies: Currency[] = [];
      currencyQuerySnapshot.forEach(currencyDocSnapshot => {
        const currency: Currency = convertToCurrency(currencyDocSnapshot.data());
        currencies.push(currency);
      });

      const lastVisible = currencyQuerySnapshot.docs[currencyQuerySnapshot.docs.length - 1];

      return new Wrapper<Currency[]>(currencies, undefined, lastVisible);
    } catch (e: any) {
      if (e.code === 'permission-denied')
        return new Wrapper<Currency[]>(undefined, $localize`You are not allowed to view currencies.`);
      return new Wrapper<Currency[]>(undefined, e.message);
    }
  }

  resetCache() {
    this.currenciesById.clear();
    this.store.dispatch(clearCurrenciesCache());
  }

  setCurrencies(currencies: Currency[]) {
    this.currencies = currencies;
    this.currenciesById = this.createCurrenciesMap(currencies);
    this.store.dispatch(setCurrencies({currencies: this.currencies, currenciesFetchDate: new Date()}));
  }

  private cacheIsOutdated() {
    return !this.lastFetch || (new Date().getTime() - this.lastFetch.getTime() > environment.defaultCurrencyCacheAgeInSec * 1000);
  }

  /**
   * Creates a map and adds the given currencies to it.
   * @param currencies currencies to be added to the map.
   * @param currenciesById map of currencies by ID. Key = currencyId, value = currency.
   */
  private createCurrenciesMap(currencies: Currency[]): Map<string, Currency> {
    const currenciesById = new Map<string, Currency>();

    if (currencies)
      for (const currency of currencies) {
        if (currency.id !== undefined)
          currenciesById.set(currency.id, currency);
      }
    return currenciesById;
  }

}

// Firestore data converter
export const currencyConverter = {
  toFirestore(currency: Currency): Currency {
    return currency;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): Currency {
    return convertToCurrency(snapshot.data(options));
  },
};
