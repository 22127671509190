import * as fromLayout from '../layout/store/layout.reducer';
import * as fromShared from '../shared/store/shared.reducer';
import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import {localStorageSync} from 'ngrx-store-localstorage';

export interface AppState {
  layout: fromLayout.State;
  shared: fromShared.State;
}

export const appReducer: ActionReducerMap<AppState> = {
  layout: fromLayout.layoutReducer,
  shared: fromShared.sharedReducer,
};

/**
 * Writes the specified store data into the local session storage and retrieves it on app start. Therefore, the state survives a browser refresh.
 * @see https://github.com/btroncone/ngrx-store-localstorage
 * @param reducer reducer
 */
export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  // const keys = ['shoppingList', 'auth', 'recipes'];
  const keys = ['shared', 'layout'];
  return localStorageSync({keys, rehydrate: true, storage: localStorage})(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

