import {NgModule} from '@angular/core';
import {DropdownDirective} from './directives/dropdown.directive';
import {PlaceholderDirective} from './directives/placeholder.directive';
import {AlertComponent} from './components/alert/alert.component';
import {ShortenPipe} from './pipes/shorten.pipe';
import {ImageModalContentComponent} from './components/image-modal-content/image-modal-content.component';
import {ImageCarouselModalContentComponent} from './components/image-carousel-modal-content/image-carousel-modal-content.component';
import {NgbCarouselModule, NgbDropdownModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from '@angular/common';
import {MoneyComponent} from './components/money/money.component';
import {LoadingSpinnerComponent} from './components/loading-spinner/loading-spinner.component';
import {MomentPipe} from './pipes/moment.pipe';
import {NotLoggedInComponent} from './components/not-logged-in/not-logged-in.component';
import {MatButtonModule} from '@angular/material/button';
import {ZeroToNullPipe} from './pipes/zeroToNull.pipe';
import {HttpClientModule} from '@angular/common/http';
import {LteDirective} from './directives/validation/lte.directive';
import {GteDirective} from './directives/validation/gte.directive';
import {RangeDirective} from './directives/validation/range.directive';
import {MatIconModule} from '@angular/material/icon';
import {InfoCardComponent} from './components/info-card/info-card.component';
import {MatIconButtonComponent} from './components/mat-icon-button/mat-icon-button.component';
import {MapComponent} from './components/map/map.component';
import {AgmCoreModule} from '@agm/core';
import {ImageCropperModule} from 'ngx-image-cropper';
import {ImageUploaderComponent} from './components/image-uploader/image-uploader.component';
import {FormsModule} from '@angular/forms';
import {DropzoneDirective} from './directives/dropzone.directive';
import {PreviewModalComponent} from './components/image-uploader/cropper/preview-modal/preview-modal.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CropperComponent} from './components/image-uploader/cropper/cropper.component';
import {UploadTaskComponent} from './components/image-uploader/upload-task/upload-task.component';
import {FilesizePipe} from './pipes/filesize.pipe';
import {ConfirmDialogComponent} from './components/confirm-dialog/confirm-dialog.component';
import {BaseComponent} from './components/base/base.component';
import {FocusAndSelectDirective} from './directives/focus-and-select.directive';
import {ElementScrollPercentageDirective} from './directives/element-scroll-percentage.directive';
import {NotEmptyDirective} from './directives/validation/not-empty.directive';
import {UserThumbComponent} from './components/user-thumb/user-thumb.component';
import {RouterModule} from '@angular/router';
import {AlertsComponent} from './components/alerts/alerts.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {TextFieldModule} from '@angular/cdk/text-field';
import {MatInputModule} from '@angular/material/input';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTableModule} from '@angular/material/table';
import {MatSortModule} from '@angular/material/sort';
import {DefaultImageDirective} from './directives/default-image.directive';
import {PublicAddressComponent} from './components/public-address/public-address.component';
import {FileSelectorComponent} from './components/file-selector/file-selector.component';
import {MatCardModule} from '@angular/material/card';
import {MatTreeModule} from '@angular/material/tree';
import {MatChipsModule} from '@angular/material/chips';
import {HighlightSearchPipe} from './pipes/highlight-search.pipe';
import {YesNoComponent} from './components/yes-no/yes-no.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {AddressTableComponent} from './components/address-table/address-table.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {RoundPipe} from './pipes/round.pipe';
import {ThumbnailWidgetComponent} from './components/thumbnail-widget/thumbnail-widget.component';
import {SocialMediaBarComponent} from './components/social-media-bar/social-media-bar.component';

@NgModule({
  declarations: [
    DropdownDirective,
    PlaceholderDirective,
    AlertComponent,
    ShortenPipe,
    ZeroToNullPipe,
    MomentPipe,
    ImageModalContentComponent,
    ImageCarouselModalContentComponent,
    MoneyComponent,
    LoadingSpinnerComponent,
    NotLoggedInComponent,
    LteDirective,
    GteDirective,
    RangeDirective,
    InfoCardComponent,
    MatIconButtonComponent,
    MapComponent,
    ImageUploaderComponent,
    DropzoneDirective,
    UploadTaskComponent,
    CropperComponent,
    PreviewModalComponent,
    FilesizePipe,
    ConfirmDialogComponent,
    BaseComponent,
    FocusAndSelectDirective,
    ElementScrollPercentageDirective,
    NotEmptyDirective,
    UserThumbComponent,
    AlertsComponent,
    DefaultImageDirective,
    PublicAddressComponent,
    FileSelectorComponent,
    HighlightSearchPipe,
    YesNoComponent,
    AddressTableComponent,
    RoundPipe,
    ThumbnailWidgetComponent,
    SocialMediaBarComponent,
  ],
  imports: [
    HttpClientModule,
    NgbCarouselModule,
    CommonModule,
    MatButtonModule,
    MatIconModule,
    AgmCoreModule,
    ImageCropperModule,
    FormsModule,
    DragDropModule,
    RouterModule,
    MatFormFieldModule,
    TextFieldModule,
    MatInputModule,
    MatTabsModule,
    MatTableModule,
    MatSortModule,
    MatCardModule,
    NgbDropdownModule,
    MatTreeModule,
    MatChipsModule,
    MatCheckboxModule,
    MatTooltipModule,
  ],
  exports: [DropdownDirective,
    PlaceholderDirective,
    AlertComponent,
    ZeroToNullPipe,
    ShortenPipe,
    MomentPipe,
    ImageModalContentComponent,
    ImageCarouselModalContentComponent,
    MoneyComponent,
    LoadingSpinnerComponent,
    NotLoggedInComponent,
    LteDirective,
    GteDirective,
    RangeDirective,
    InfoCardComponent,
    MatIconButtonComponent,
    MapComponent,
    ImageUploaderComponent,
    ImageCropperModule,
    FocusAndSelectDirective,
    NotEmptyDirective,
    UserThumbComponent,
    AlertsComponent,
    DefaultImageDirective,
    PublicAddressComponent,
    FileSelectorComponent,
    HighlightSearchPipe,
    YesNoComponent,
    AddressTableComponent,
    RoundPipe,
    ThumbnailWidgetComponent, SocialMediaBarComponent,
  ],
})
export class SharedModule {
}
