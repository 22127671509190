import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {StartComponent} from './pages/start/start.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';

const appRoutes: Routes = [
  {path: '', component: StartComponent},
  {path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
  {path: '', loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule)},
  {path: '', loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)},
  {path: '**', component: NotFoundComponent},

  // Redirection = Bad user experience. The url will be lost
  // {path: '**', redirectTo: '/not-found'}
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, {
    preloadingStrategy: PreloadAllModules,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
