import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import {addUserPublicToCache, addUserToCache, clearCurrenciesCache, clearUserCache, setCurrencies} from './shared.actions';
import {environment} from '../../../environments/environment';
import {Currency} from '../models/currency.interface';
import {UserPublic} from '../models/userPublic.interface';
import {User} from '../models/user.interface';


export interface State {
  currencies: Currency[];
  currenciesFetchDate?: Date;
  usersPublic: UserPublic[];
  users: User[];
}

export const initialState: State = {
  usersPublic: [],
  users: [],
  currencies: [],
};

function logDebugMessages(actionName: string, state: State, newState: any): void {
  if (environment.enableReducerLogging) {
    console.log((new Date()).toLocaleString() + ': ' + actionName);
    console.log(state);
    console.log(newState);
  }
}

const reducer: ActionReducer<State, Action> = createReducer(
  initialState,

  on(clearCurrenciesCache, (state) => {
    const newState = {...state, currencies: [], currenciesFetchDate: undefined};
    logDebugMessages('clearCurrenciesCache', state, newState);
    return newState;
  }),
  on(setCurrencies, (state, {currencies, currenciesFetchDate}) => {
    const newState = {...state, currencies, currenciesFetchDate};
    logDebugMessages('setCurrencies', state, newState);
    return newState;
  }),
  on(addUserPublicToCache, (state, {userPublic}) => {
    const newUsersPublic: UserPublic[] = [];
    const nowUtc = new Date().getTime();
    for (const userPublicFromState of state.usersPublic) {
      // Skip the user with the ID of the given user, because we will add the given userPublic object later
      if (userPublic.uid === userPublicFromState.uid)
        continue;
      // Also skip, if the cached userPublic is too old (or has no cache date)
      if (!userPublicFromState.cacheDate || (nowUtc - userPublicFromState.cacheDate.getTime() > environment.defaultUserPublicCacheAgeInSec * 1000))
        continue;
      const userPublicCopy: UserPublic = {...userPublicFromState};
      newUsersPublic.push(userPublicCopy);
    }
    newUsersPublic.push(userPublic);
    const newState = {...state, usersPublic: newUsersPublic};

    logDebugMessages('addUserPublicToCache', state, newState);
    return newState;
  }),

  on(addUserToCache, (state, {user}) => {
    const newUsers: User[] = [];
    const nowUtc = new Date().getTime();
    for (const userFromState of state.users) {
      // Skip the user with the ID of the given user, because we will add the given user object later
      if (user.uid === userFromState.uid)
        continue;
      // Also skip, if the cached user is too old (or has no cache date)
      if (!userFromState.cacheDate || (nowUtc - userFromState.cacheDate.getTime() > environment.defaultUserCacheAgeInSec * 1000))
        continue;
      const userCopy: User = {...userFromState};
      newUsers.push(userCopy);
    }
    newUsers.push(user);
    const newState = {...state, users: newUsers};

    logDebugMessages('addUserToCache', state, newState);
    return newState;
  }),
  on(clearUserCache, (state) => {
    const newUsers: User[] = [];
    const newState = {...state, users: newUsers};
    logDebugMessages('clearUserCache', state, newState);
    return newState;
  }),
);

export function sharedReducer(state: State | undefined, action: Action): State {
  return reducer(state, action);
}
