import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../shared/services/user.service';
import {TitleService} from '../../shared/services/title.service';
import {environment} from '../../../environments/environment';
import {LEAD_BLITZSHARE, LEAD_PHOTOGRAPHY, LEAD_REAL_ESTATE, LEAD_SOFTWARE_DEVELOPER} from '../../shared/constants/strings';
import {UtilService} from '../../shared/util.service';
import {MetadataService} from '../../shared/services/metadata.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss'],
})
export class StartComponent extends BaseComponent implements OnInit {

  imgWidths = [2000, 1500, 1000, 750, 500, 350];
  imgPath = environment.storageImgPath;

  constructor(
    authService: AuthService,
    userService: UserService,
    private metadataService: MetadataService,
    private utilService: UtilService,
    private titleService: TitleService) {
    super(authService, userService);
  }

  public get LEAD_SOFTWARE_DEVELOPER() {
    return LEAD_SOFTWARE_DEVELOPER;
  }

  public get LEAD_PHOTOGRAPHY() {
    return LEAD_PHOTOGRAPHY;
  }

  public get LEAD_REAL_ESTATE() {
    return LEAD_REAL_ESTATE;
  }

  public get LEAD_BLITZSHARE() {
    return LEAD_BLITZSHARE;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setRawTitle(environment.defaultTitle);
    this.metadataService.updateTags($localize`Startseite`,
      $localize`Digitale Visitenkarte von Alexander Schwartz - Software-Entwickler, Fotograf, Immobilieninvestor und Gründer von Blitzshare.de`,
      $localize`aSchwartz, Software entwicklung, Fotografie, Immobilien, Investments, Blitzshare.de`,
      undefined,
      environment.applicationRootUrl,
    );

  }

  goToMyServices(): void {
    this.utilService.scrollToId('my-services');
  }
}
