import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../shared/services/user.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends BaseComponent implements OnInit, OnDestroy {

  contactMail = environment.contactMail;
  imgPath = environment.storageImgPath;

  constructor(
    authService: AuthService,
    userService: UserService,
    private router: Router) {
    super(authService, userService);
  }

  ngOnInit(): void {
    super.ngOnInit();

  }

  ngOnDestroy(): void {
  }


}
