<div class="modal-body">
  <button (click)="activeModal.dismiss('Cross click')" aria-label="Close" class="close" type="button">
    <span aria-hidden="true">&times;</span>
  </button>
  <br>
  <app-mat-icon-button *ngIf="deleteImageCallback" [callback]="onDeleteImage.bind(this)" [params]="[]"
                       color="warn" cssClass="mb-3" i18n-label="Button label delete image" icon="delete"
                       label="Delete this image"></app-mat-icon-button>

  <ngb-carousel #imageCarousel="ngbCarousel" [activeId]="selectedId+''">
    <ng-template (slid)="selectedId = i" *ngFor="let imgUrl of imgUrls; index as i" id="{{i}}" ngbSlide>
      <div class="wrapper">
        <picture>
          <source [srcset]="imgUrl.w350" media="(max-width: 350px)">
          <source [srcset]="imgUrl.w500" media="(max-width: 500px)">
          <source [srcset]="imgUrl.w750" media="(max-width: 750px)">
          <source [srcset]="imgUrl.w1124" media="(max-width: 1124px)">
          <source [srcset]="imgUrl.w1500" media="(max-width: 1500px)">
          <img *ngIf="isImageActive(i)" [src]="imgUrl.full" alt="{{imgUrl.title}}" i18n-alt="Alt text of image in carousel modal">
        </picture>

      </div>
    </ng-template>
  </ngb-carousel>

</div>
