import {Component, OnInit, ViewChild} from '@angular/core';
import {LEAD_BLITZSHARE} from '../../shared/constants/strings';
import {environment} from '../../../environments/environment';
import {ImgUrls} from '../../shared/models/imgUrls.interface';
import {NgbCarousel, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilService} from '../../shared/util.service';
import {ImageCarouselModalContentComponent} from '../../shared/components/image-carousel-modal-content/image-carousel-modal-content.component';
import Util from '../../shared/util';
import {MetadataService} from '../../shared/services/metadata.service';
import {TitleService} from '../../shared/services/title.service';

const PHOTO_COUNT_OBJECT = 57;

@Component({
  selector: 'app-blitzshare',
  templateUrl: './blitzshare.component.html',
  styleUrls: ['./blitzshare.component.scss'],
})
export class BlitzshareComponent implements OnInit {

  activeImageIndexObject = 0;
  imgPath = environment.storageImgPath;
  imgPathBlitzshare = environment.storageImgPath + '/blitzshare/';
  titleImage = this.imgPathBlitzshare + 'blitzshare-canoe-w600.webp';
  imgUrlsObject: ImgUrls[] = [];
  @ViewChild('imageCarouselObject') imageCarouselObject: NgbCarousel | undefined;

  constructor(
    private modalService: NgbModal,
    private metadataService: MetadataService,
    private utilService: UtilService,
    private titleService: TitleService) {
  }

  public get LEAD_BLITZSHARE() {
    return LEAD_BLITZSHARE;
  }

  ngOnInit(): void {
    this.titleService.setTitle($localize`Blitzshare`);
    this.metadataService.updateTags($localize`Blitzshare`,
      $localize`Hier erfahren Sie mehr über meine Plattform blitzshare.de`,
      $localize`Blitzshare.de Gründer Start-up sharing-community`,
      [this.titleImage],
    );


    this.createImgUrls(1, PHOTO_COUNT_OBJECT, 'carouselObject');

  }

  /**
   * Opens the given array of images in a carousel inside a modal.
   * @param selectedId the ID of the image to be selected at the beginning
   * @param imgUrls all images to be shown in the carousel
   */
  openImage(selectedId: number, imgUrls: ImgUrls[]): void {
    const modalRef = this.modalService.open(ImageCarouselModalContentComponent, {
      windowClass: 'myCustomModalClass',
    });
    modalRef.componentInstance.selectedId = selectedId;
    modalRef.componentInstance.imgUrls = imgUrls;
    modalRef.componentInstance.altName = imgUrls[selectedId].title;
  }

  isImageActive(i: number, carousel: 'carouselObject') {
    switch (carousel) {
      case 'carouselObject':
        return Util.isSlideActive(i, this.activeImageIndexObject, this.imgUrlsObject!.length);
    }

  }


  selectImage(index: number, carousel: 'carouselObject') {
    let imageCarousel;
    switch (carousel) {
      case 'carouselObject':
        imageCarousel = this.imageCarouselObject;
        break;
    }
    if (!imageCarousel?.activeId)
      return;
    imageCarousel.select('' + index);
    this.utilService.scrollToId(carousel);
  }

  private createImgUrls(firstIndex: number, lastIndex: number, carousel: 'carouselObject') {
    switch (carousel) {
      case 'carouselObject':
        this.imgUrlsObject = [];
        for (let i = firstIndex; i <= lastIndex; i++)
          this.imgUrlsObject.push(
            {
              w150: this.imgPathBlitzshare + `category${i}-w150.webp`,
              w350: this.imgPathBlitzshare + `category${i}-w350.webp`,
              w500: this.imgPathBlitzshare + `category${i}-w500.webp`,
              w750: this.imgPathBlitzshare + `category${i}-w750.webp`,
              w1124: this.imgPathBlitzshare + `category${i}-w1124.webp`,
              w1500: this.imgPathBlitzshare + `category${i}-w1500.webp`,
              full: this.imgPathBlitzshare + `category${i}-w2000.webp`,
              title: $localize`Kategorienfoto ${i}`,
            });
        break;
    }
    Util.shuffle(this.imgUrlsObject);

  }
}
