<table class="table table-striped table-sm details-table">
  <tbody>
  <tr>
    <th i18n>Name</th>
    <td>Alexander Schwartz</td>
  </tr>
  <tr>
    <th i18n>Anschrift</th>
    <td>Normannenstr. 6<br>
      81925 München<br>
      Deutschland
    </td>
  </tr>
  <tr>
    <th i18n>Email</th>
    <td><a href="mailto:mail@aSchwartz.de">mail@aSchwartz.de</a></td>
  </tr>
  <tr>
    <th i18n>Telefon</th>
    <td>+49 (0) 171 / 5432107</td>
  </tr>
  <tr *ngIf="showTaxNumber">
    <th i18n>Umsatzsteuer-Identifikationsnummer</th>
    <td>DE270827078</td>
  </tr>
  <tr *ngIf="showBusinessInfo">
    <th i18n>Registrierungsnummer</th>
    <td>A21064834</td>
  </tr>
  <tr *ngIf="showBusinessInfo">
    <th i18n>Gemeinde</th>
    <td i18n>Bayerische Landeshauptstadt München
    </td>
  </tr>
  <tr *ngIf="showBusinessInfo">
    <th i18n>Gemeindeschlüssel</th>
    <td i18n>09162000
    </td>
  </tr>
  <tr *ngIf="showMessenger">
    <th i18n>WhatsApp</th>
    <td><i class="fa fa-whatsapp"></i> +491715432107</td>
  </tr>
  <tr *ngIf="showMessenger">
    <th i18n>Telegram</th>
    <td><i class="fa fa-telegram"></i> erSchistan</td>
  </tr>
  <tr *ngIf="showMessenger">
    <th i18n>Facebook Messenger</th>
    <td><i class="fa fa-facebook"></i> ersch.istan</td>
  </tr>
  <tr *ngIf="showMessenger">
    <th i18n>Skype</th>
    <td><i class="fa fa-skype"></i> mail@aSchwartz.de</td>
  </tr>
  </tbody>
</table>

<app-map *ngIf="showMap" [disabled]="true" [lat]="lat" [lng]="lng" [zoom]="14"></app-map>
