import {Component, OnInit} from '@angular/core';
import {MetadataService} from '../../shared/services/metadata.service';
import {TitleService} from '../../shared/services/title.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css'],
})
export class PrivacyPolicyComponent implements OnInit {

  constructor(
    private metadataService: MetadataService,
    private titleService: TitleService) {
  }

  ngOnInit(): void {
    this.titleService.setTitle($localize`Datenschutzerklärung`);
    this.metadataService.updateTags($localize`Datenschutzerklärung`,
      $localize`Hier können Sie meine Datenschutzerklärung lesen.`,
      $localize`Datenschutz Datenschutzerklärung privacy policy`,
    );
  }

}
