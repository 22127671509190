import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ImprintComponent} from '../pages/imprint/imprint.component';
import {PrivacyPolicyComponent} from '../pages/privacy-policy/privacy-policy.component';
import {SupportComponent} from '../pages/support/support.component';
import {SoftwareDevelopmentComponent} from './software-development/software-development.component';
import {PhotographyComponent} from './photography/photography.component';
import {RealEstateComponent} from './real-estate/real-estate.component';
import {BlitzshareComponent} from './blitzshare/blitzshare.component';
import {ContactComponent} from './contact/contact.component';

const layoutRoutes: Routes = [
  {path: 'software-development', component: SoftwareDevelopmentComponent},
  {path: 'photography', component: PhotographyComponent},
  {path: 'real-estate', component: RealEstateComponent},
  {path: 'blitzshare', component: BlitzshareComponent},
  {path: 'imprint', component: ImprintComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'support', component: SupportComponent},
  {path: 'contact', component: ContactComponent},
];

@NgModule({
  imports: [RouterModule.forChild(layoutRoutes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {
}
