import packageJson from '../../package.json';

export const environment = {
  production: false,


  // -------------------------------------------------------------------------------------------------
  // Basic app settings
  // -------------------------------------------------------------------------------------------------

  applicationRootUrl: 'https://dev.aSchwartz.de',
  version: packageJson.version,
  // The suffix to be added to the html title attribute, e.g. resulting in 'Imprint | aSchwartz'
  defaultTitleSuffix: ' | aSchwartz.de dev',
  defaultTitle: 'aSchwartz.de dev',
  contactMail: 'mail@aSchwartz.de',
  contactFormMail: 'web-contact@aSchwartz.de',
  homeLatitude: 48.1545847,
  homeLongitude: 11.6172677,


  // -------------------------------------------------------------------------------------------------
  // External links
  // -------------------------------------------------------------------------------------------------

  linkLinkedIn: 'https://www.linkedin.com/in/alexander-schwartz-59219417a/',
  linkFacebook: 'https://www.facebook.com/ersch.istan/',
  linkYoutube: 'https://www.youtube.com/c/AlexanderSchwartz88',
  linkTwitter: 'https://twitter.com/erSch',
  linkXing: 'https://www.xing.com/profile/Alexander_Schwartz22/cv',
  linkInstagram: 'https://www.instagram.com/erschistan/',

  // -------------------------------------------------------------------------------------------------
  // Default fetch counts (firestore, algolia)
  // -------------------------------------------------------------------------------------------------

  // default number of public user documents to be loaded from the server
  defaultLoadUserUserPublicCount: 5,

  // -------------------------------------------------------------------------------------------------
  // Default cache durations (time, after which a cached entry should be refetched)
  // -------------------------------------------------------------------------------------------------

  defaultCurrencyCacheAgeInSec: 1800,
  defaultUserCacheAgeInSec: 5,
  defaultUserPublicCacheAgeInSec: 300,

  // -------------------------------------------------------------------------------------------------
  // Firebase config
  // -------------------------------------------------------------------------------------------------

  firebaseConfig: {
    apiKey: 'AIzaSyDHLACCQinByoYwuTj8eyzpQTr5kH1Zlow',
    authDomain: 'aschwartz-de-dev.firebaseapp.com',
    databaseURL: 'https://aschwartz-de-dev-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'aschwartz-de-dev',
    storageBucket: 'aschwartz-de-dev.appspot.com',
    publicStorageBucket: 'aschwartz-de-dev-public',
    messagingSenderId: '758967376174',
    appId: '1:758967376174:web:f38f8a7edcb415459402bf',
    measurementId: 'G-VCXV18Z5NF',
    regionName: 'europe-west3',
  },


  // fallback language for the firestore, if the selected firestoreLocale is not available (e.g. for categories and currencies).
  // defaultFirestoreLocale should always be 'en'. The interfaces require 'en'-entries, but don't require any other locales.
  defaultFirestoreLocale: 'en',

  // -------------------------------------------------------------------------------------------------
  // Firestore collection names
  // -------------------------------------------------------------------------------------------------

  firestoreCollectionCurrencies: 'currencies',
  firestoreCollectionMails: 'mail',
  firestoreCollectionUsers: 'users',
  firestoreCollectionUsersPublic: 'usersPublic',

  // -------------------------------------------------------------------------------------------------
  // Email templates
  // -------------------------------------------------------------------------------------------------

  mailTemplateMessage: 'message',
  mailTemplateMessageConfirmation: 'messageConfirmation',

  // -------------------------------------------------------------------------------------------------
  // Storage paths
  // -------------------------------------------------------------------------------------------------

  storageImgPath: 'https://storage.googleapis.com/aschwartz-de-public/img',

  // -------------------------------------------------------------------------------------------------
  // Misc. API keys
  // -------------------------------------------------------------------------------------------------

  geoCodingApiKey: 'AIzaSyAHLWvRUoU78ItWTICsAjNoqOx4ccVhreU',
  translationApiKey: 'AIzaSyDYF9Mb9Pe9CzwbOn1R90AT4h6WDjJFE6k',


  // -------------------------------------------------------------------------------------------------
  // Facebook
  // -------------------------------------------------------------------------------------------------

  facebookCatalogId: 265639308896209,


  // -------------------------------------------------------------------------------------------------
  // Misc
  // -------------------------------------------------------------------------------------------------

  // Determines, how big the rectangle on the map is, which is shown for a listing. 7 means:	≤ 153m	×	153m. See https://www.movable-type.co.uk/scripts/geohash.html
  geohashPrecision: 7,
  // Suffix to be put in the filename of generated thumbnails before uploading them
  thumbnailSuffix: 'thumb',
  // This is either the width or the height, whichever is smaller, in which thumbnails are created to be uploaded.
  // Thumbnails will have a minimum width and height of 300px.
  thumbnailSize: 300,
  maxImagesCount: 15,


  // -------------------------------------------------------------------------------------------------
  // Debugging
  // -------------------------------------------------------------------------------------------------

  enableReducerLogging: false,


};


// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
