<div *ngIf="snapshot | async as snap">
  <div *ngIf="file?.name" i18n="Uploading file">Uploading {{file?.name}}.</div>
  <span i18n="transferred filesize of complete filesize">Transferred {{ snap.bytesTransferred | filesize }} of {{ snap.totalBytes | filesize }}.</span>

  <div *ngIf="percentage | async as percentage">
    <progress [value]="percentage" max="100"></progress>
    {{ percentage | number:'1.0-1': numberFormatLocale }}%
  </div>

  <div *ngIf="!downloadURL">
    <button (click)="task.pause()" *ngIf="task" [disabled]="!isActive(snap)" color="primary"
            i18n-matTooltip="tooltip pause upload" id="pauseButton"
            mat-flat-button matTooltip="Pause the upload">
      <mat-icon>pause</mat-icon>
    </button>
    <button (click)="task.resume()" *ngIf="task" [disabled]="!(snap?.state === 'paused')" color="primary"
            i18n-matTooltip="tooltip resume upload" id="resumeButton"
            mat-flat-button matTooltip="Resume the upload">
      <mat-icon>play_arrow</mat-icon>
    </button>
  </div>
</div>
