import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {State} from '../store/shared.reducer';
import {Store} from '@ngrx/store';
import * as fromApp from '../../store/app.reducer';
import {environment} from '../../../environments/environment';
import {addUserPublicToCache, addUserToCache, clearUserCache} from '../store/shared.actions';
import {User} from '../models/user.interface';
import {UserPublic} from '../models/userPublic.interface';
import {Mutex} from 'async-mutex';
// @ts-ignore
import firebase, {DocumentSnapshot} from 'firebase';
import {takeUntil} from 'rxjs/operators';
import {Wrapper} from '../models/wrapper.model';
import {DocumentData, QueryDocumentSnapshot, SnapshotOptions} from '@angular/fire/firestore';
import {convertToUser, convertToUserPublic} from '../converters/modelConverters';
import {firestore} from '../../app.module';
import Timestamp = firebase.firestore.Timestamp;

@Injectable({
  providedIn: 'root',
})
export class UserService {

  userMutex = new Mutex();
  userPublicMutex = new Mutex();
  destroy$: Subject<null> = new Subject();
  sharedObservable: Observable<State>;
  usersPublicById: Map<string, UserPublic> = new Map<string, UserPublic>();
  usersById: Map<string, User> = new Map<string, User>();

  constructor(
    private store: Store<fromApp.AppState>) {
    this.sharedObservable = this.store.select(state => state.shared);
    // Note: ngOnInit is not called in a service class
    this.init();
  }

  init(): void {
    this.sharedObservable.pipe(takeUntil(this.destroy$)).subscribe(state => {
      if (state.usersPublic) {
        this.usersPublicById = new Map<string, UserPublic>();
        state.usersPublic.forEach(userPublic => {
          if (userPublic.uid)
            this.usersPublicById.set(userPublic.uid, userPublic);
        });
      }
      if (state.users) {
        this.usersById = new Map<string, User>();
        state.users.forEach(user => {
          if (user.uid)
            this.usersById.set(user.uid, user);
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
  }

  async fetchUserPublic(uid: string, maxAgeInSec: number = environment.defaultUserPublicCacheAgeInSec): Promise<Wrapper<UserPublic>> {
    return this.userPublicMutex.runExclusive(async () => {
      const userPublicFromCache = this.usersPublicById.get(uid);
      if (userPublicFromCache !== undefined && this.isUserUpToDate(userPublicFromCache, maxAgeInSec))
        return new Wrapper<UserPublic>(userPublicFromCache);

      try {
        const userPublicDocSnapshot = await firestore.collection(environment.firestoreCollectionUsersPublic).doc(uid).withConverter(userPublicConverter).get();
        const userPublic = userPublicDocSnapshot.data();
        if (userPublic) {
          const userPublicWithIdAndCacheDate: UserPublic = {...userPublic, uid: userPublicDocSnapshot.id, cacheDate: new Date()};
          this.addUserPublicToCache(userPublicWithIdAndCacheDate);
          return new Wrapper<UserPublic>(userPublicWithIdAndCacheDate);
        }
      } catch (e: any) {
        return new Wrapper<UserPublic>(undefined, e.message);
      }

      // If no user was found
      return new Wrapper<UserPublic>(undefined);

    });
  }


  async fetchUsersPublic(startAfter?: DocumentSnapshot, limit: number = environment.defaultLoadUserUserPublicCount): Promise<Wrapper<UserPublic[]>> {
    try {
      let query = firestore.collection(environment.firestoreCollectionUsersPublic)
        .orderBy('creationDate', 'desc')
        .withConverter(userPublicConverter);
      if (limit)
        query = query.limit(limit);
      if (startAfter)
        query = query.startAfter(startAfter);
      const userPublicQuerySnapshot = await query.get();
      const usersPublic: UserPublic[] = [];
      userPublicQuerySnapshot.forEach(userPublicDocSnapshot => {
        const userPublic: UserPublic | undefined = userPublicDocSnapshot.data();
        if (userPublic) {
          const userPublicWithId = {...userPublic, uid: userPublicDocSnapshot.id};
          usersPublic.push(userPublicWithId);
        }
      });
      const lastVisible = userPublicQuerySnapshot.docs[userPublicQuerySnapshot.docs.length - 1];

      return new Wrapper<UserPublic[]>(usersPublic, undefined, lastVisible);
    } catch (e: any) {
      if (e.code === 'permission-denied')
        return new Wrapper<UserPublic[]>(undefined, $localize`You are not allowed to view these users.`);
      return new Wrapper<UserPublic[]>(undefined, e.message);
    }
  }


  async fetchUser(uid: string, maxAgeInSec: number = environment.defaultUserCacheAgeInSec): Promise<Wrapper<User>> {
    return this.userMutex.runExclusive(async () => {
      const userFromCache = this.usersById.get(uid);
      if (userFromCache !== undefined && this.isUserUpToDate(userFromCache, maxAgeInSec))
        return new Wrapper<User>(userFromCache);

      try {
        const userDocSnapshot = await firestore.collection(environment.firestoreCollectionUsers).doc(uid).withConverter(userConverter).get();
        const user = userDocSnapshot.data();
        if (user) {
          const userWithIdAndCacheDate: User = {...user, uid: userDocSnapshot.id, cacheDate: new Date()};
          this.addUserToCache(userWithIdAndCacheDate);
          return new Wrapper<User>(userWithIdAndCacheDate);
        }
      } catch (e: any) {
        return new Wrapper<User>(undefined, e.message);
      }

      // If no user was found
      return new Wrapper<User>(undefined);
    });
  }

  /**
   * Sends the given user to the backend server. If it has an ID, an existing user is updated, otherwise a new one is added.   *
   * @param user user to be sent. Need to be provided, even if a fullUser is given
   * @param fullUser user to be written to the cache. This user is not sent to the firestore
   * @param merge if true, only the fields given in the user object will be updated. Otherwise, the whole user object will be overwritten
   * @param onSuccessCallback callback to be called on success
   * @param onErrorCallback callback to be called on error
   */
  updateUser(user: User, fullUser: User | undefined, merge: boolean, onSuccessCallback: () => void, onErrorCallback: (error: string) => void): void {
    const userWithLastEditDate: User = {...user, lastEditDate: Timestamp.now()};
    firestore.collection(environment.firestoreCollectionUsers).doc(user.uid).set(userWithLastEditDate, {merge}).then(
      () =>
        onSuccessCallback(),
      (error) =>
        onErrorCallback($localize`The user could not be updated\: ${error}`),
    );
    if (merge && !fullUser) {
      console.error('updateUser called with a merge job without providing a fullUser.');
      return;
    }
    this.addUserToCache(merge && fullUser ? fullUser : user);

  }

  /**
   * Sends the given userPublic to the backend serPublicver. If it has an ID, an existing userPublic is updated, otherwise a new one is added.
   * @param uid ID of the userPublic to be sent
   * @param userPublic userPublic to be sent. Need to be provided, even if a fullUserPublic is given
   * @param fullUserPublic userPublic to be written to the cache. This userPublic is not sent to the firestore
   * @param merge if true, only the fields given in the userPublic object will be updated. Otherwise, the whole userPublic object will be overwritten
   * @param onSuccessCallback callback to be called on success
   * @param onErrorCallback callback to be called on error
   */
  updateUserPublic(userPublic: UserPublic, fullUserPublic: UserPublic | undefined, merge: boolean, onSuccessCallback: () => void, onErrorCallback: (error: string) => void): void {
    firestore.collection(environment.firestoreCollectionUsersPublic).doc(userPublic.uid).set(userPublic, {merge}).then(
      () =>
        onSuccessCallback(),
      (error) =>
        onErrorCallback($localize`The userPublic could not be updated\: ${error}`),
    );
    if (merge && !fullUserPublic) {
      console.error('updateUserPublic called with a merge job without providing a fullUserPublic.');
      return;
    }
    this.addUserPublicToCache(merge && fullUserPublic ? fullUserPublic : userPublic);

  }

  clearUserCache() {
    this.store.dispatch(clearUserCache());
  }


  /**
   * Checks, if the given user or userPublic is newer then the given max age.
   * @param userPublic user or userPublic to be checked
   * @param maxAgeInSec max age in seconds
   * @return true, if newer, false otherwise
   */
  private isUserUpToDate(user: UserPublic | User, maxAgeInSec: any): boolean {
    if (!user.cacheDate)
      return false;
    const cacheTime = user.cacheDate.getTime();
    const now = new Date().getTime();
    const ageInSec = (now - cacheTime) / 1000;
    return (ageInSec < maxAgeInSec);
  }

  private addUserPublicToCache(userPublic: UserPublic) {
    this.usersPublicById.set(userPublic.uid, userPublic);
    this.store.dispatch(addUserPublicToCache({userPublic}));
  }

  private addUserToCache(user: User) {
    this.usersById.set(user.uid, user);
    this.store.dispatch(addUserToCache({user}));
  }
}


// Firestore data converter
export const userPublicConverter = {
  toFirestore(userPublic: UserPublic): UserPublic {
    return userPublic;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): UserPublic {
    return convertToUserPublic(snapshot.data(options));
  },
};

// Firestore data converter
export const userConverter = {
  toFirestore(user: User): User {
    return user;
  },
  fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>, options: SnapshotOptions): User {
    return convertToUser(snapshot.data(options));
  },
};

