import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../shared/services/user.service';
import {TitleService} from '../../shared/services/title.service';
import {MetadataService} from '../../shared/services/metadata.service';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.css'],
})
export class ImprintComponent extends BaseComponent implements OnInit {


  constructor(
    authService: AuthService,
    userService: UserService,
    private metadataService: MetadataService,
    private titleService: TitleService) {
    super(authService, userService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.titleService.setTitle($localize`Imprint`);

    this.metadataService.updateTags($localize`Impressum`,
      $localize`Hier erfahren Sie, wer für aSchwartz.de verantwortlich ist.`,
      $localize`Impressum Kontaktmöglichkeiten`,
    );
  }

}
