<div class="container p-2">

  <div class="jumbotron mb-3">
    <h1 class="jumbotron-headline" i18n="Imprint headline">Impressum</h1>
    <p class="jumbotron-lead" i18n="Imprint lead text">Auf dieser Seite erfahren Sie, wer für aSchwartz.de verantwortlich ist.</p>
  </div>

  <h1 class="paragraph-headline" i18n>Seitenbetreiber und Verantwortlicher</h1>
  <app-responsible-contact-details [showBusinessInfo]="true" [showMap]="true" [showTaxNumber]="true"></app-responsible-contact-details>


</div>
