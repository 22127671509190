import {Component, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-social-media-bar',
  templateUrl: './social-media-bar.component.html',
  styleUrls: ['./social-media-bar.component.scss'],
})
export class SocialMediaBarComponent implements OnInit {

  linkLinkedIn = environment.linkLinkedIn;
  linkFacebook = environment.linkFacebook;
  linkYoutube = environment.linkYoutube;
  linkTwitter = environment.linkTwitter;
  linkXing = environment.linkXing;
  linkInstagram = environment.linkInstagram;

  constructor() {
  }

  ngOnInit(): void {
  }

}
