import {Action} from '@ngrx/store';
import {Observable} from 'rxjs';
import firebase from 'firebase/app';
// for cloud firestore
import {Coordinates} from './models/coordinates.interface';
import * as moment from 'moment';
import {ElementRef} from '@angular/core';
import Timestamp = firebase.firestore.Timestamp;

export const IMAGE_FILE_TYPES = ['png', 'jpg', 'jpeg', 'gif', 'bpg'];

export default class Util {


  /**
   * Removes the given element from the given array and returns the array. The original array is changed by this.
   * @param array array, from which the element should be removed. This array is changed by this
   * @param element element to be removed. If it is not contained, nothing happens
   * @return given array without the element
   */
  static removeFromArray<T>(array: T[], element: T): T[] {
    const index = array.indexOf(element);
    if (index > -1)
      array.splice(index, 1);
    return array;
  }

  /**
   * Shuffles the given array.
   * @param array shuffled array
   */
  static shuffle<T>(array: T[]): T[] {
    let currentIndex = array.length, randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {

      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  /**
   * Delivers the given date without the time. This is done by setting hours, minutes, seconds and milliseconds to 0.
   * @param date date, from which the time should be removed
   * @return date without time.
   */
  static getDateWithoutTime(date: Date): Date {
    return new Date(this.getTimestampWithoutTime(date));
  }

  /**
   * Converts the given parameter to a date, if it is something date-like.
   * @param date date parameter. Can be a firebase timestamp, a Date or have the fields seconds and nanoseconds
   * @return Date
   */
  static getDate(date: any): Date {
    if (date instanceof Timestamp)
      return date.toDate();
    if (date instanceof Date)
      return date;
    if (date.seconds !== undefined && date.nanoseconds !== undefined)
      return Timestamp.fromMillis(date.seconds * 1000 + date.nanoseconds / 1000).toDate();
    return date;
  }

  /**
   * Delivers the given date as a timestamp without the time. This is done by setting hours, minutes, seconds and milliseconds to 0.
   * @param date date, from which the time should be removed
   * @return date as timestamp without time.
   */
  static getTimestampWithoutTime(date: Date): number {
    // Don't modify the original date
    const newDate = new Date(date.getTime());
    return newDate.setHours(0, 0, 0, 0);
  }

  /**
   * Delivers the given date as a timestamp without the time. This is done by setting hours, minutes, seconds and milliseconds to 0.
   * @param timestamp timestamp, from which the time should be removed
   * @return date as timestamp without time.
   */
  static removeTimeFromTimestamp(timestamp: number): number {
    return this.getTimestampWithoutTime(new Date(timestamp));
  }

  /**
   * Delivers the given firebase timestamp as a number timestamp without the time. This is done by setting hours, minutes, seconds and milliseconds to 0.
   * @param timestamp firebase timestamp, from which the time should be removed
   * @return date as number timestamp without time.
   */
  static removeTimeFromFirebaseTimestamp(timestamp: Timestamp): number {
    return this.removeTimeFromTimestamp(timestamp.seconds * 1000);
  }


  static auxObservable(action: Action): Observable<any> {
    return new Observable((observer) => {
      observer.next(action);
    });
  }


  /**
   * Scrolls to the top of the page smoothly. By default scrolling is done with about 60fps (every 16 ms),
   * 333 pixels per step. But this can be configured with the parameters
   * @param scrollDistancePerStep distance to be scrolled per step.
   * @param stepPeriod period of each step. If stepPeriod is 0, the animation is omitted and scrolling is done instantly.
   */
  static scrollToTop(stepPeriod: number = 16, scrollDistancePerStep: number = 333): void {
    if (stepPeriod === 0) {
      window.scroll(0, 0);
      return;
    }

    const scrollToTop = window.setInterval(() => {
      const pos = window.pageYOffset;
      if (pos > 0) {
        window.scrollTo(0, pos - scrollDistancePerStep); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, stepPeriod);
  }

  /**
   * Returns the given value, if it's not undefined, or null, if it is
   * @param value any value
   * @return the given value, if it's not undefined, or null, if it is
   */
  static valueOrNull<T>(value: T | undefined): T | null {
    if (!value)
      return null;
    return value;
  }

  /**
   * Calculates the distance between two given sets of coordinates
   * @param coords1
   * @param coords2
   */
  public static getDistanceInKm(coords1: Coordinates, coords2: Coordinates): number {
    const R = 6371e3; // metres
    const omega1 = coords1!.lat * Math.PI / 180; // φ, λ in radians
    const omega2 = coords2!.lat * Math.PI / 180;
    const phiOmega = (coords2!.lat - coords1!.lat) * Math.PI / 180;
    const phiLambda = (coords2!.lng - coords1!.lng) * Math.PI / 180;

    const a = Math.sin(phiOmega / 2) * Math.sin(phiOmega / 2) +
      Math.cos(omega1) * Math.cos(omega2) *
      Math.sin(phiLambda / 2) * Math.sin(phiLambda / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const d = R * c; // in metres
    return d / 1000;
  }

  /**
   * Prints an array of strings as a comma separated list. After every comma, there is a space. After the last element, there is no comma
   * @param strings array of strings
   * @return pretty string
   */
  public static prettyPrintArray(strings: string[]): string {
    let result = '';
    for (let i = 0; i < strings.length; i++) {
      const str = strings[i];
      result += str;
      if (i < strings.length - 1)
        result += ', ';
    }
    return result;
  }

  /**
   * Delivers the file extension based on the given file name. The file itself is not validated.
   * @param filename name of the file
   * @return file extension as a string or undefined, if there is none or if it can't be determined
   */
  public static getFileExtension(filename: string | undefined): string | undefined {
    if (!filename)
      return undefined;
    const parts = filename.split('.');
    if (parts.length < 2)
      return undefined;
    return parts[parts.length - 1].toLowerCase();
  }


  /**
   * Validates, if the file is an image.
   * @param file file to be validated
   * @param onValid callback for valid image
   * @param onInvalid callback for invalid image
   * @return true, if the file is a valid image, false otherwise
   */
  public static validateImageFile(file: File, onValid: (file: File) => void, onInvalid: (error: string) => void): void {

    if (!this.validateFileType(file, IMAGE_FILE_TYPES))
      return onInvalid(file.name + ': ' + $localize`Invalid filetype. Allowed filetypes are\:` + ' ' + Util.prettyPrintArray(IMAGE_FILE_TYPES));


    const image = new Image();

    image.onload = () => {
      onValid(file);
    };
    image.onerror = () => {
      onInvalid(file.name + ': ' + $localize`File is not a valid image.`);
    };

    // The following line is necessary for image.onload or .onerror to get called. If nothing is done with the image, neither function gets called.
    image.src = URL.createObjectURL(file);
  }

  /**
   * Validates, if the file has one of the given file types.
   * @param file file to be validated
   * @param fileTypes accepted file types array. If undefined, all file types are valid. If empty, no file types are valid.
   * @return true, if the file is a valid image, false otherwise
   */
  public static validateFileType(file: File, fileTypes?: string[]): boolean {
    // If no file types are given, all are valid
    if (fileTypes === undefined)
      return true;
    const fileExtension = Util.getFileExtension(file.name);
    return !(!fileExtension || fileTypes?.indexOf(fileExtension) === -1);
  }

  /**
   * Rounds the given value to the given number of decimal places, if necessary.
   * Example Input:
   * 10
   * 1.7777777
   * 9.1
   * Output:
   * 10
   * 1.78
   * 9.1
   * @param value number to round
   * @param decimalPlaces max number of decimal places
   * @return rounded value
   */
  public static round(value: number, decimalPlaces: number) {
    const helper = Math.pow(10, decimalPlaces);
    return Math.round((value + Number.EPSILON) * helper) / helper;
  }

  /**
   * Ceils the given value to the given number of decimal places, if necessary.
   * Example Input:
   * 10
   * 1.7777777
   * 9.1
   * Output:
   * 10
   * 1.78
   * 9.1
   * @param value number to ceil
   * @param decimalPlaces max number of decimal places
   * @return ceiled value
   */
  public static ceil(value: number, decimalPlaces: number) {
    const helper = Math.pow(10, decimalPlaces);
    return Math.ceil((value + Number.EPSILON) * helper) / helper;
  }

  /**
   * Removes the params from the given url. Those are the part of the url beginning with something like '?param=value'.
   * @param url url with or without params
   * @return url without params. If the url does not contain a '?', it's returned as it is.
   */
  static getUrlWithoutParams(url: string): string {
    if (url.indexOf('?') === -1)
      return url;
    const parts = url.split('?');
    return parts[0];
  }

  /**
   * Removes everything from the url but the path within the storage. Example:
   * Input: https://firebasestorage.googleapis.com/v0/b/aschwartz-de.appspot
   *        .com/o/img%2Flistings%2FxBOUipA4qWyXvAez8gtH%2F2021-04-07_14-00-53.5353_20200303_131920.thumb.webp
   * Output: /img/listings/xBOUipA4qWyXvAez8gtH/2021-04-07_14-12-44.4444_20200308_174414.thumb.webp
   * @param url
   */
  static getRefFromUrl(url: string) {
    if (url.indexOf('/') === -1)
      return url;
    const parts = url.split('/');
    const splitString: string = parts[parts.length - 1];
    return '/' + splitString.replace(/%2F/g, '/');
  }

  /**
   * Creates a from now string from the given date. It will be formatted in the language set in environment.momentLocale
   * @param date date, for which the from now string should be created.
   * @return from now string
   */
  static createFromNowString(date: Date): string {
    return moment(date).fromNow();
  }

  /**
   * Compares the two given values to determine, which one should come first, when sorting
   * @param a value one
   * @param b value two
   * @param isAsc if true, the smaller value will come first, otherwise the bigger value
   * @return if isAsc: -1, if a is the smaller value, 1 otherwise. if! isAsc: -1, if a is the bigger value, 1 otherwise
   */
  static compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  static upperCaseFirstLetter(input: string): string {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }

  static lowerCaseFirstLetter(input: string): string {
    return input.charAt(0).toLowerCase() + input.slice(1);
  }

  /**
   * Removes <em>...</em> tags from the given input. The content of the tag remains.
   * @param input some string with or without em tag
   * @return input string after removing em tags (and leaving tag content)
   */
  static removeEmTags(input?: string): string {
    if (!input)
      return '';
    return input.replace('<em>', '').replace('</em>', '');
  }

  static parseTime(timeString: string): { hours: number, minutes: number } {
    if (timeString.indexOf('AM') > -1 || timeString.indexOf('PM') > -1) {
      return this.getHoursAndMinutes(moment(timeString, ['h:mm A']));
    }
    return this.getHoursAndMinutes(moment(timeString, ['hh:mm']));
  }

  static getHoursAndMinutes(momentTime: moment.Moment): { hours: number, minutes: number } {
    const hours = momentTime.hours();
    const minutes = momentTime.minutes();
    return {hours, minutes};
  }

  /**
   * Returns true, if the two dates are the same day (not counting the time).
   * @param date1 date and time 1
   * @param date2 date and time 2
   * @return true, if same day, false otherwise.
   */
  static isSameDay(date1: Date, date2: Date) {
    return this.getTimestampWithoutTime(date1) === this.getTimestampWithoutTime(date2);
  }

  /**
   * Inserts the given data object into the firestore
   * @param data data to be written
   * @param db firestore instance
   * @param fireStoreCollectionPath the path of the collection, where the data should be written to
   * @param onSuccessCallback Callback to be called after successful writing. Passes the documentUid.
   * @param onErrorCallback Callback to be called, if something goes wrong.
   */
  static insertData(data: any, db: firebase.firestore.Firestore, fireStoreCollectionPath: string,
                    onSuccessCallback?: ((docUid: string) => void), onErrorCallback?: ((reason: string) => void)): void {
    db.collection(fireStoreCollectionPath).add(data)
      .then(docRef => {
        if (onSuccessCallback)
          onSuccessCallback(docRef.id);
      })
      .catch((errorResponse) => {
        if (onErrorCallback)
          onErrorCallback(errorResponse.message);
      });
  }

  /**
   * Checks, if the given testDate is between the given dateFrom and dateUntil. If so, returns true, otherwise false.
   * @param testDate date to be tested
   * @param dateFrom date from
   * @param dateUntil date until
   * @return true, if testDate is in between, false, if not
   */
  static isBetween(testDate: Date, dateFrom: Date, dateUntil: Date) {
    return testDate.getTime() > dateFrom.getTime() && testDate.getTime() < dateUntil.getTime();
  }

  /**
   * Checks, if the given testDate is between the given dateFrom and dateUntil or equals either date. If so, returns true, otherwise false.
   * @param testDate date to be tested
   * @param dateFrom date from
   * @param dateUntil date until
   * @return true, if testDate is in between or equals either date, false, if not
   */
  static isBetweenOrEquals(testDate: Date, dateFrom: Date, dateUntil: Date) {
    return testDate.getTime() >= dateFrom.getTime() && testDate.getTime() <= dateUntil.getTime();
  }

  static getFullDaysBetween(startDate: Date, endDate: Date): number[] {
    const fullDays: number[] = [];

    for (const d = this.getDateWithoutTime(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
      const nextDay: Date = new Date(d.getTime());
      nextDay.setDate(d.getDate() + 1);
      if (d.getTime() >= startDate.getTime() && nextDay.getTime() <= endDate.getTime())
        fullDays.push(d.getTime());
    }
    return fullDays;
  }

  /**
   * Format bytes as human-readable text.
   *
   * @param bytes Number of bytes.
   * @param si True to use metric (SI) units, aka powers of 1000. False to use
   *           binary (IEC), aka powers of 1024.
   * @param dp Number of decimal places to display.
   *
   * @return Formatted string.
   */
  static humanFileSize(bytes: number, si = false, dp = 1) {
    const thresh = si ? 1000 : 1024;

    if (Math.abs(bytes) < thresh) {
      return bytes + ' B';
    }

    const units = si
      ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
      : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    let u = -1;
    const r = 10 ** dp;

    do {
      bytes /= thresh;
      ++u;
    } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

    return bytes.toFixed(dp) + ' ' + units[u];
  }

  /**
   * Converts a file to a base64 string.
   * @param file file to be converted
   * @param onSuccessCallback callback containing the base64 string after successful conversion
   * @param onErrorCallback callback, if something went wrong
   */
  static convertFiletoBase64(file: File, onSuccessCallback: (base64: any) => void,
                             onErrorCallback: (error: any) => void): void {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => onSuccessCallback(reader.result);
    reader.onerror = error => onErrorCallback(error);
  };

  /**
   * Converts all given files to base64 strings
   * @param files all files to be converted
   * @param base64Files already converted base64 files. Used internally (for recursive calls). You can pass an empty error, when you call this method.
   * @param fileIndex index of the file to be converted next. Used internally (for recursive calls). You can pass 0, when you call this method.
   * @param onFinishedCallback callback containing all converted base64 strings after successful conversion
   * @param onErrorCallback callback, if something went wrong
   */
  static convertFilesToBase64(files: File[], base64Files: string[], fileIndex: number, binaryOnly: boolean, onFinishedCallback: (base64: string[]) => void, onErrorCallback: (error: any, incompleteBase64: string[]) => void): void {
    this.convertFiletoBase64(files[fileIndex], (base64 => {
        if (binaryOnly) {
          const parts = base64.split(';base64,');
          base64 = parts[1];
        }
        base64Files.push(base64);
        if (fileIndex < files.length - 1)
          this.convertFilesToBase64(files, base64Files, ++fileIndex, binaryOnly, onFinishedCallback, onErrorCallback);
        else
          onFinishedCallback(base64Files);
      }),
      (error => {
        onErrorCallback(error, base64Files);
      }));
  }


  /**
   * Delivers the domain part of the URL, e.g. https://blitzshare.de or http://localhost:4200
   */
  static getDomain(): string {
    return window.location.origin;
  }

  /**
   * Delivers the path part of the URL, e.g. /en/account/listings
   */
  static getPath(): string {
    return (location.pathname + location.search);
  }

  /**
   * Scrolls to the element with the given elementRef.
   * @param elementRef element reference of the target element. Use with @ViewChild('element') element?: ElementRef;
   */
  static scrollToElementRef(elementRef?: ElementRef) {
    if (!elementRef)
      return;
    // Use a timeout to give the target component some time to load (if necessary)
    setTimeout(args => {
      const targetElement = elementRef?.nativeElement;
      targetElement?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }, 50);
  }

  /**
   * Determines, if the slide (of a carousel) is active. Those slides are active:
   * - the previous one (or the last one, if the current one is the first)
   * - the current one
   * - the next one (or the first one, if the current one is the last)
   * This can be used for lazy loading of images. Only the current, previous and next images will be loaded and not all at once.
   * @param index index of the slide
   * @param activeIndex the currently shown slide
   * @param slideCount number of slides
   */
  public static isSlideActive(index: number, activeIndex: number, slideCount: number) {
    // Current slide
    if (index === activeIndex)
      return true;
    // Next slide
    if (index === activeIndex + 1)
      return true;
    // Previous slide
    if (index === activeIndex - 1)
      return true;
    // If this is the first slide, the last one
    if (activeIndex === 0 && index === slideCount - 1)
      return true;
    // If this is the last slide, the first one
    if (activeIndex === slideCount - 1 && index === 0)
      return true;
    // All other slides are inactive
    return false;
  }
}
