import {Component, ElementRef, EventEmitter, OnInit} from '@angular/core';
import {BaseComponent} from '../../../shared/components/base/base.component';
import {Router} from '@angular/router';
import {AuthService} from '../../auth.service';
import {UserService} from '../../../shared/services/user.service';
import firebase from 'firebase/app';
import {AngularFireAuth} from '@angular/fire/auth';
import {AuthProvider} from 'ngx-auth-firebaseui';
import {AnalyticsEventName, AnalyticsService} from '../../../shared/services/analytics.service';
import {FacebookEventName, FacebookService} from '../../../shared/services/facebook.service';
import FirebaseAuthUser = firebase.User;


@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
})
export class LoginDialogComponent extends BaseComponent implements OnInit {
  closeEventEmitter?: EventEmitter<void> = new EventEmitter<void>();
  providers = AuthProvider;


  constructor(authService: AuthService,
              userService: UserService,
              private router: Router,
              private analyticsService: AnalyticsService,
              private facebookService: FacebookService,
              private afAuth: AngularFireAuth) {
    super(authService, userService);
  }

  ngOnInit() {
    super.ngOnInit();


    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.firebaseAuthUser = user;
      } else {
        this.firebaseAuthUser = undefined;
      }
    });

    // Subscribe to changes of firebase auth state
    this.afAuth.onAuthStateChanged(user => {
      if (user) {
        this.authService.onFirebaseSignIn(user);
      } else {
        this.authService.onFirebaseLogout();
      }
    });

  }

  closeDialog() {
    this.closeEventEmitter?.emit();
  }

  onSuccessfulLogin(user: FirebaseAuthUser): void {
    const accountCreation = user.metadata.creationTime === user.metadata.lastSignInTime;
    if (accountCreation) {
      // Don't change the user object in the firestore at this point. ngx-auth-ui does some manipulations of the user and might overwrite any changes, we do here.
      console.log('New account was created.');
      this.analyticsService.logEvent(AnalyticsEventName.ACCOUNT_CREATED);
      this.facebookService.logEvent(FacebookEventName.CompleteRegistration, {content_name: user.uid});
      this.router.navigate(['/account/firstSteps']);
    }

    console.log(user);
    this.closeDialog();
  }

  handleError($event: any): void {
    console.log($event);
  }

}

/**
 * Class to represent login dialog model.
 */
export class LoginDialogModel {

  constructor(public target: ElementRef, public closeEventEmitter?: EventEmitter<void>) {
  }
}

