<h1 [innerHTML]="title" class="paragraph-headline" mat-dialog-title></h1>

<img *ngIf="imageUrl" [src]="imageUrl" class="cropped-300h-fullWidth mb-2"
     default="./assets/images/logos/default-image300w.png">

<div mat-dialog-content>
  <p [innerHTML]="message"></p>
</div>

<div *ngIf="primaryButton==='yes'" mat-dialog-actions>
  <button (click)="onConfirm()" appFocusAndSelect class="mr-2" color="primary" i18n="yes" mat-flat-button>Yes</button>
  <button (click)="onDismiss()" i18n="no" mat-flat-button>No</button>
</div>

<div *ngIf="primaryButton==='no'" mat-dialog-actions>
  <button (click)="onDismiss()" appFocusAndSelect class="mr-2" i18n="no" mat-flat-button>No</button>
  <button (click)="onConfirm()" color="warn" i18n="yes" mat-flat-button>Yes</button>
</div>
