import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../shared/components/base/base.component';
import {AuthService} from '../../auth/auth.service';
import {UserService} from '../../shared/services/user.service';
import {TitleService} from '../../shared/services/title.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MailService} from '../../shared/services/mail.service';
import {Mail} from '../../shared/models/mail.interface';
import firebase from 'firebase';
import {MailTemplate} from '../../shared/models/mailTemplate.interface';
import {environment} from '../../../environments/environment';
import {MESSAGE_CATEGORIES, MessageCategory} from '../../shared/models/messageCategory.interface';
import {FacebookEventName, FacebookService} from '../../shared/services/facebook.service';
import {Editor, Toolbar} from 'ngx-editor';
import schema from '../../shared/config/editorSchema';
import {ActivatedRoute} from '@angular/router';
import {MetadataService} from '../../shared/services/metadata.service';
import Timestamp = firebase.firestore.Timestamp;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent extends BaseComponent implements OnInit {


  senderNameMaxLength = 100;
  senderEmailMaxLength = 200;
  senderPhoneMaxLength = 200;
  subjectMaxLength = 150;
  messageMinLength = 10;
  messageMaxLength = 100000;
  messageForm!: FormGroup;
  messageSent = false;
  messageCategories: MessageCategory[] = MESSAGE_CATEGORIES;

  editorExpanded: boolean = false;
  /**
   * WYSIWYG editor for message
   */
  editor: Editor = new Editor({
    history: true,
    keyboardShortcuts: true,
    inputRules: true,
    schema: schema,
  });
  toolbarSm1: Toolbar = [['bold', 'italic', 'underline', 'strike'],
    ['text_color', 'background_color']];
  toolbarSm2: Toolbar = [[{heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']}],
    ['ordered_list', 'bullet_list'], ['code']];
  toolbarSm3: Toolbar = [['align_left', 'align_center', 'align_right', 'align_justify'], ['link', 'image']];
  toolbarMd1: Toolbar = [['bold', 'italic', 'underline', 'strike'],
    ['text_color', 'background_color'], [{heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6']}]];
  toolbarMd2: Toolbar = [['ordered_list', 'bullet_list'], ['code'], ['align_left', 'align_center', 'align_right', 'align_justify'], ['link', 'image']];
  toolbar: Toolbar = [
    ...this.toolbarSm1,
    ...this.toolbarSm2,
    ...this.toolbarSm3,
    // ['blockquote'],
  ];
  private readonly spinnerKeySendingMessage = 'sendingMessage';
  private categoryId?: string;

  constructor(
    authService: AuthService,
    userService: UserService,
    private metadataService: MetadataService,
    private formBuilder: FormBuilder,
    private mailService: MailService,
    private route: ActivatedRoute,
    private facebookService: FacebookService,
    private titleService: TitleService) {
    super(authService, userService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.metadataService.updateTags($localize`Kontakt`,
      $localize`Kontaktmöglichkeiten und Kontaktformular`,
      $localize`SKontaktmöglichkeiten Kontaktformular Email Telefon Adresse Whatsapp Telegram Facebook Messenger Skype`,
    );
    this.titleService.setTitle($localize`Kontakt`);

    this.route.queryParams.subscribe(params => {
      this.categoryId = params['categoryId'];
    });
    this.messageForm = this.createMessageForm();
  }

  onSendMessage(): void {

    const senderName = this.messageForm.value.senderName?.trim();
    if (!senderName) {
      this.addError($localize`Bitte geben Sie einen Betreff ein`);
      return;
    }
    const senderEmail = this.messageForm.value.senderEmail?.trim();
    if (!senderEmail) {
      this.addError($localize`Bitte geben Sie einen Betreff ein`);
      return;
    }
    const senderPhone = this.messageForm.value.senderPhone?.trim();
    const subject = this.messageForm.value.subject?.trim();
    if (!subject) {
      this.addError($localize`Bitte geben Sie einen Betreff ein`);
      return;
    }
    const message = this.messageForm.value.message?.trim();
    if (!message) {
      this.addError($localize`Bitte geben Sie eine Nachricht ein.`);
      return;
    }

    const categoryId = this.messageForm.value.categoryId;

    this.addLoadingSpinnerMessage(this.spinnerKeySendingMessage, $localize`Sending message...`);
    const mailTemplate: MailTemplate = {
      name: environment.mailTemplateMessage, data: {message, senderName, senderEmail, subject},
    };
    switch (categoryId) {
      case 'software-development':
        mailTemplate.data.softwareDevelopment = true;
        break;
      case 'real-estate':
        mailTemplate.data.realEstate = true;
        break;
      case 'photography':
        mailTemplate.data.photography = true;
        break;
      case 'blitzshare':
        mailTemplate.data.blitzshare = true;
        break;
    }
    if (senderPhone)
      mailTemplate.data.senderPhone = senderPhone;

    const mail: Mail = {
      creationDate: Timestamp.now(), template: mailTemplate,
      from: `${senderName} über aSchwartz <${environment.contactFormMail}>`,
      to: `Alexander Schwartz <${environment.contactFormMail}>`,
      replyTo: `${senderName} <${senderEmail}>`,

    };
    this.mailService.sendMail(mail).then(() => {
        this.removeLoadingSpinnerMessage(this.spinnerKeySendingMessage);
        this.messageSent = true;
        this.addSuccess($localize`Ihre Nachricht wurde erfolgreich an mich gesendet.`);
        this.sendConfirmationMessageToSender(mail);
        this.facebookService.logEvent(FacebookEventName.Contact);
      },
      error => {
        this.addError($localize`Fehler beim Senden Ihrer Nachricht\: ${error}`);
        this.removeLoadingSpinnerMessage(this.spinnerKeySendingMessage);
      });
  }

  resetForm(): void {
    this.messageSent = false;
    this.messageForm.reset();
    this.editor.setContent('');
    this.clearAlerts();
  }

  private createMessageForm(): FormGroup {
    const builder = this.formBuilder;

    return builder.group({
      senderName: [null, Validators.required],
      senderEmail: [null, [Validators.required, Validators.email]],
      senderPhone: [null, Validators.pattern('(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})')],
      categoryId: [this.categoryId],
      subject: [null, Validators.required],
      message: [null, [Validators.required, Validators.minLength(this.messageMinLength), Validators.maxLength(this.messageMaxLength)]],
    });

  }

  private sendConfirmationMessageToSender(mail: Mail) {

    mail.to = mail.replyTo;
    mail.from = `Alexander Schwartz <${environment.contactFormMail}>`;
    mail.replyTo = `Alexander Schwartz <${environment.contactFormMail}>`;
    mail.template.name = environment.mailTemplateMessageConfirmation;
    mail.template.data.contactFormMail = environment.contactFormMail;

    this.mailService.sendMail(mail).then(() => {
        this.removeLoadingSpinnerMessage(this.spinnerKeySendingMessage);
        this.messageSent = true;
        this.addSuccess($localize`Wir haben Ihnen eine Bestätigung an Ihre Emailadresse gesendet.`);
        this.messageForm.reset();
      },
      error => {
        this.addError($localize`Fehler beim Senden der Bestätigungsnachricht\: ${error}`);
        this.removeLoadingSpinnerMessage(this.spinnerKeySendingMessage);
      });
  }
}
