export interface MessageCategory {
  id: string;
  value: string;
}

export const MESSAGE_CATEGORIES: MessageCategory[] = [
  {id: 'software-development', value: $localize`Software-Entwicklung`},
  {id: 'real-estate', value: $localize`Immobilien`},
  {id: 'photography', value: $localize`Fotografie`},
  {id: 'blitzshare', value: $localize`Blitzshare`},
];
