import {Component, OnInit} from '@angular/core';
import {environment} from '../../../environments/environment';
import {LEAD_SOFTWARE_DEVELOPER} from '../../shared/constants/strings';
import {MetadataService} from '../../shared/services/metadata.service';
import {TitleService} from '../../shared/services/title.service';

@Component({
  selector: 'app-software-development',
  templateUrl: './software-development.component.html',
  styleUrls: ['./software-development.component.scss'],
})
export class SoftwareDevelopmentComponent implements OnInit {

  imgPath = environment.storageImgPath;
  titleImage = this.imgPath + '/start/software-development.webp';

  constructor(
    private metadataService: MetadataService,
    private titleService: TitleService) {
  }

  public get LEAD_SOFTWARE_DEVELOPER() {
    return LEAD_SOFTWARE_DEVELOPER;
  }

  ngOnInit(): void {
    this.titleService.setTitle($localize`Software-Entwicklung`);
    this.metadataService.updateTags($localize`Software-Entwicklung`,
      $localize`Hier erfahren Sie alles über meine Leistungen als Software-Entwickler.`,
      $localize`Software entwicklung java kotlin angular android`,
      [this.titleImage],
    );

  }

}
