<div class="container p-2">

  <!-- On small screens, show vertical layout (text and button below image) -->
  <div class="card w-100 mb-3 d-block d-md-none">
    <div class="row no-gutters">
      <div class="col">
        <div class="jumbotron-cropped-image-w100">
          <img [src]="titleImage" alt="Software-Entwicklung" class="top-rounded-image" i18n-alt>
        </div>
      </div>
      <div class="col p-3">
        <ng-container *ngTemplateOutlet="jumbotronContent"></ng-container>
      </div>
    </div>
  </div>

  <!-- On larger screens, show horizontal layout (text and button next to image) -->
  <div class="card w-100 mb-3 d-md-block d-none">
    <div class="row no-gutters">
      <div class="jumbotron-cropped-image-w300px">
        <img [src]="imgPath + '/start/software-development.webp'" alt="Software-Entwicklung" class="top-rounded-image" i18n-alt>
      </div>
      <div class="col">
        <div class="card-body">
          <ng-container *ngTemplateOutlet="jumbotronContent"></ng-container>
        </div>
      </div>
    </div>
  </div>
  <ng-template #jumbotronContent>
    <h1 class="jumbotron-headline" i18n>Software-Entwicklung</h1>
    <p class="jumbotron-lead">{{LEAD_SOFTWARE_DEVELOPER}}</p>
    <a [queryParams]="{categoryId: 'software-development'}" routerLink="/contact">
      <button color="primary" mat-flat-button>Kontaktieren Sie mich mit Ihrem Auftrag!</button>
    </a>
  </ng-template>

  <h1 class="paragraph-headline" i18n>Projekterfahrungen (Auszug)</h1>

  <h2 class="paragraph-headline-2" i18n>Blitzshare.de</h2>

  <table class="table table-striped table-sm table-responsive table-bordered">
    <tbody>
    <tr>
      <th i18n>Zeitraum</th>
      <td i18n>01/2021 - heute</td>
    </tr>
    <tr>
      <th i18n>Branche</th>
      <td i18n>Sharing economy</td>
    </tr>
    <tr>
      <th i18n>Standort</th>
      <td i18n>München</td>
    </tr>
    <tr>
      <th i18n>Positionsbezeichnung</th>
      <td i18n>Gründer und Full-Stack-Entwickler</td>
    </tr>
    <tr>
      <th i18n>Aufgaben</th>
      <td>
        <ul>
          <li i18n>Entwicklung einer <a href="https://blitzshare.de" target="_blank" rel="noreferrer">Single-Page-Web-App</a> in Angular 12</li>
          <li i18n>Entwicklung einer Backend-Server-Applikation in Kotlin</li>
          <li i18n>Anbindung der Google Cloud Platform (Firebase): Authentication, Firestore NoSQL-Database, Storage, Cloud Functions und Hosting</li>
          <li i18n>Anbindung diverser APIs, z.B. Google Geocoding API, Google Translation API, Algolia Search, Twilio SendGrid</li>
        </ul>
      </td>
    </tr>
    <tr>
      <th i18n>Technisches Umfeld</th>
      <td>
        <ul>
          <li i18n>Angular 12</li>
          <li i18n>Kotlin 1.4.21</li>
          <li i18n>Spring Boot 2.4.2</li>
          <li i18n>Google Cloud Plattform (Firebase)</li>
          <li i18n>Git, GitHub, SourceTree</li>
          <li i18n>IntelliJ Webstorm, IntelliJ IDEA</li>
        </ul>
      </td>
    </tr>
    </tbody>
  </table>

 <h2 class="paragraph-headline-2" i18n>Hypoport (Vergleich.de / Dr. Klein)</h2>

  <table class="table table-striped table-sm table-responsive table-bordered">
    <tbody>
    <tr>
      <th i18n>Zeitraum</th>
      <td i18n>10/2021 – 09/2022</td>
    </tr>
    <tr>
      <th i18n>Branche</th>
      <td i18n>Finanzen</td>
    </tr>
    <tr>
      <th i18n>Standort</th>
      <td i18n>Lübeck (Remote)</td>
    </tr>
    <tr>
      <th i18n>Positionsbezeichnung</th>
      <td i18n>Fullstack-Entwickler (Java/Kotlin/Angular)</td>
    </tr>
    <tr>
      <th i18n>Aufgaben</th>
      <td>
        <ul>
          <li i18n>Weiterentwicklung der Websites vergleich.de und drklein.de</li>
          <li i18n>Entwicklung neuer sowie Weiterentwicklung bestehender Backend-Services </li>
          <li i18n>Entwicklung von Monitoring-Tools</li>
          <li i18n>Deployment über TeamCity sowie GitHub Actions</li>
          <li i18n>Entwicklung von REST-Schnittstellen</li>
        </ul>
      </td>
    </tr>
    <tr>
      <th i18n>Technisches Umfeld</th>
      <td>
        <ul>
          <li i18n>Java und Kotlin (inkl. Spring, Spring Boot)</li>
          <li i18n>Angular (Typescript) und JavaScript</li>
          <li i18n>IntelliJ IDEA und Webstorm</li>
          <li i18n>Apache Maven</li>
          <li i18n>Oracle SQL Developer </li>
          <li i18n>Git (GitLab, GitHub, BitBucket) </li>
          <li i18n>TeamCity</li>
          <li i18n>Jira</li>
        </ul>
      </td>
    </tr>
    </tbody>
  </table>


  <h2 class="paragraph-headline-2" i18n>Backend-Entwicklung im öffentlichen Dienst</h2>

  <table class="table table-striped table-sm table-responsive table-bordered">
    <tbody>
    <tr>
      <th i18n>Zeitraum</th>
      <td i18n>02/2019 – 12/2020</td>
    </tr>
    <tr>
      <th i18n>Branche</th>
      <td i18n>Öffentlicher Dienst</td>
    </tr>
    <tr>
      <th i18n>Standort</th>
      <td i18n>München</td>
    </tr>
    <tr>
      <th i18n>Positionsbezeichnung</th>
      <td i18n>Backend-Entwickler (Java)</td>
    </tr>
    <tr>
      <th i18n>Aufgaben</th>
      <td>
        <ul>
          <li i18n>Mitentwicklung des Basisframeworks für Online-Dienste der Landeshauptstadt München</li>
          <li i18n>Weiter- und Neuentwicklung von neuen Online-Diensten mit Hilfe des Basisframeworks</li>
          <li i18n>Anbindung an bestehende Basiskomponenten wie ePayment oder Backend-lntegration (per Webservice)</li>
          <li i18n>Erstellen bzw. Anpassen von technischer Dokumentation der entwickelten Programmteile</li>
          <li i18n>Prüfung und Spezifikation der technischen Einsatzmöglichkeiten zusätzlicher Produkte oder Frameworks zur Webentwicklung</li>
        </ul>
      </td>
    </tr>
    <tr>
      <th i18n>Technisches Umfeld</th>
      <td>
        <ul>
          <li i18n>Java (inkl. Spring und Hibernate), JavaScript</li>
          <li i18n>Eclipse IDE for Java EE Developers 2019-06</li>
          <li i18n>Apache Maven und Camel</li>
          <li i18n>Oracle SQL Developer</li>
          <li i18n>Git und Subversion</li>
          <li i18n>Jenkins</li>
          <li i18n>SonarQube</li>
          <li i18n>Mantis und TestLink</li>
          <li i18n>LiMux (basierend auf Ubuntu 18.04 LTS)</li>
        </ul>
      </td>
    </tr>
    </tbody>
  </table>


  <h2 class="paragraph-headline-2" i18n>Android App-Entwicklung</h2>

  <table class="table table-striped table-sm table-responsive table-bordered">
    <tbody>
    <tr>
      <th i18n>Zeitraum</th>
      <td i18n>02/2016 – 12/2020 (in Teilzeit)</td>
    </tr>
    <tr>
      <th i18n>Branche</th>
      <td i18n>Spiele, Fitness</td>
    </tr>
    <tr>
      <th i18n>Standort</th>
      <td i18n>München</td>
    </tr>
    <tr>
      <th i18n>Positionsbezeichnung</th>
      <td i18n>Android App-Entwickler</td>
    </tr>
    <tr>
      <th i18n>Aufgaben</th>
      <td>
        <ul>
          <li i18n>Entwicklung einer <a href="https://play.google.com/store/apps/details?id=de.yamma.boozeup&hl=de" target="_blank" rel="noreferrer">Kartenspiel-App</a></li>
          <li i18n>Entwicklung einer Fitness-Tracking-App mit Spring Boot Server-Komponente</li>
          <li i18n>Entwicklung einer <a href="https://play.google.com/store/apps/details?id=de.aschwartz.beertracker&hl=de" target="_blank" rel="noreferrer">Gesundheits-Tracking-App</a>
          </li>
          <li i18n>Installation und Administration eines Ubuntu-Servers und der nötigen Software für die Server-Komponente</li>
          <li i18n>Entwicklung einer Gesundheits-App</li>
        </ul>
      </td>
    </tr>
    <tr>
      <th i18n>Technisches Umfeld</th>
      <td>
        <ul>
          <li i18n>Java, Kotlin, Android Framework</li>
          <li i18n>Gradle (App), Maven (Server-Anwendung)</li>
          <li i18n>Android Studio (App), Eclipse EE (Server-Anwendung)</li>
          <li i18n>Ubuntu 16.04.5 LTS</li>
          <li i18n>Apache, Tomcat, MySQL, phpMyAdmin</li>
          <li i18n>Git</li>
          <li i18n>Putty und WinSCP</li>
        </ul>
      </td>
    </tr>
    </tbody>
  </table>


  <h2 class="paragraph-headline-2" i18n>Backend-Entwicklung in der Medienbranche</h2>

  <table class="table table-striped table-sm table-responsive table-bordered">
    <tbody>
    <tr>
      <th i18n>Zeitraum</th>
      <td i18n>12/2015 – 02/2019</td>
    </tr>
    <tr>
      <th i18n>Branche</th>
      <td i18n>IT/Medien</td>
    </tr>
    <tr>
      <th i18n>Standort</th>
      <td i18n>München</td>
    </tr>
    <tr>
      <th i18n>Positionsbezeichnung</th>
      <td i18n>Backend-Entwickler (Java) und Team Leader Entwicklung</td>
    </tr>
    <tr>
      <th i18n>Aufgaben</th>
      <td>
        <ul>
          <li i18n>Entwicklung neuer Programmmodule sowie Verbesserung und Erweiterung bestehender (primär Backend, auch Frontend)</li>
          <li i18n>Leitung eines Entwicklungsteams aus 5 Personen</li>
          <li i18n>Code Reviews und Fehleranalysen</li>
          <li i18n>Datenimporte -und Exporte (XML/XSD, Apache POI etc.)</li>
          <li i18n>Entwicklung von RESTful Webservices</li>
          <li i18n>Migration von ANT zu Maven</li>
        </ul>
      </td>
    </tr>
    <tr>
      <th i18n>Technisches Umfeld</th>
      <td>
        <ul>
          <li i18n>Java 9 (inkl. Spring, Spring Boot, Spring Batch, Spring Security und Querydsl), JavaScript (inkl. JQuery) und Oracle SQL</li>
          <li i18n>Maven</li>
          <li i18n>Eclipse IDE for Java EE Developers 4.8 (Photon)</li>
          <li i18n>Oracle SQL Developer</li>
          <li i18n>Git und Subversion</li>
          <li i18n>Putty und WinSCP</li>
          <li i18n>Jenkins</li>
          <li i18n>SonarQube</li>
          <li i18n>Jira und OTRS</li>
          <li i18n>Windows 10 Pro</li>
        </ul>
      </td>
    </tr>
    </tbody>
  </table>


</div>
