import {createAction, props} from '@ngrx/store';
import {Currency} from '../models/currency.interface';
import {UserPublic} from '../models/userPublic.interface';
import {User} from '../models/user.interface';


export const clearCurrenciesCache = createAction('[Currency] clearing currency cache');
export const addCurrencyToCache = createAction('[Currency] adding currency to cache', props<{ currency: Currency }>());
export const setCurrencies = createAction('[Shared] Setting currencies', props<{ currencies: Currency[], currenciesFetchDate: Date }>());
export const addUserPublicToCache = createAction('[Shared] Adding userPublic to cache', props<{ userPublic: UserPublic }>());
export const addUserToCache = createAction('[Shared] Adding user to cache', props<{ user: User }>());
export const clearUserCache = createAction('[Shared] Clearing user cache');
export const clearUsers = createAction('[Shared] Clearing all users');






